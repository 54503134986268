import isAfter from "date-fns/isAfter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { fetchMaxWorkersByLocationJob } from "../../../../../../../js/features/ManageJobs/ManageJobsSlice";
import { calculateShiftHoursAndMinutes } from "../../../../../../utils/helpers";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import { SchedulerWorkCenter } from "../../../../store/types";
import {
	configureDatesOfTheWeek,
	getInitValues,
	yupInitObject,
} from "../utils";

type UseNewShiftDialogProps = {
	draftShiftDate: Date;
};

type JobRole = any;

type FormValues = {
	startTime: string;
	endTime: string;
	numberOfWorkersNeeded: string;
};

const useNewShiftDialog = ({ draftShiftDate }: UseNewShiftDialogProps) => {
	const dispatch = useDispatch();

	const [filteredJobs, setFilteredJobs] = useState<JobRole[]>([]);
	const [currentLocationId, setCurrentLocationId] = useState("");
	const [lastSelectedJobId, setLastSelectedJobId] = useState("");
	// const [lastSelectedHexColor, setLastSelectedHexColor] = useState("");
	// const [lastSelectedWorkcenterId, setLastSelectedWorkcenterId] = useState("");
	const [shiftType, setShiftType] = useState("jobs");
	const {
		allJobRoles,
		filterJobRoles,
		filterAllWorkcenters,
		fetchFilterAndShiftDataStatus,
		defaultDurationHrs,
		weekStartDay,
		filterAllSkills,
		allSkillLevels,
		defaultShiftsSkillLevel,
	} = useSchedulerData();

	const initialValues = useMemo(
		() =>
			getInitValues({
				draftShiftDate,
				defaultDurationHrs,
				shiftPurposeId: 0,
				weekStartDay,
			}),
		[draftShiftDate, defaultDurationHrs, weekStartDay]
	);

	const schema = useMemo(() => Yup.object(yupInitObject), []);

	const [totalHours, setTotalHours] = useState(0);
	const [totalMins, setTotalMins] = useState(0);
	const [initialShiftDateTimeValues, setInitialShiftDateTimeValue] =
		useState(initialValues);
	const [selectedDatesOfTheWeek, setSelectedDatesOfTheWeek] = useState(() =>
		configureDatesOfTheWeek(new Date(initialValues.startTime), weekStartDay)
	);

	useEffect(() => {
		const initValues = getInitValues({
			draftShiftDate,
			defaultDurationHrs,
			shiftPurposeId: 0,
			weekStartDay,
		});
		setInitialShiftDateTimeValue(initValues);
		setSelectedDatesOfTheWeek(
			configureDatesOfTheWeek(new Date(initValues.startTime), weekStartDay)
		);
	}, [draftShiftDate, defaultDurationHrs, weekStartDay]);

	const filterAndAutoSelectJobs = useCallback(
		(workcenterId: string, setFieldvalue?: any) => {
			const numericWorkcenterId = parseInt(workcenterId);
			let activeJobs: JobRole[] = [];

			if (fetchFilterAndShiftDataStatus === "fulfilled") {
				const selectedWorkcenter = filterAllWorkcenters.find(
					(workcenter: SchedulerWorkCenter) =>
						workcenter.id === numericWorkcenterId
				);

				if (selectedWorkcenter) {
					const jobIdSet = new Set(
						selectedWorkcenter?.jobIds?.split(",").map(Number)
					);
					activeJobs = filterJobRoles.filter(
						(job: JobRole) => jobIdSet.has(job.id) && job.isActive
					);
				}
			} else {
				activeJobs = allJobRoles.filter(
					(job: JobRole) =>
						job.workCenterIds.includes(workcenterId) && job.isActive
				);
			}

			setFilteredJobs(activeJobs);

			if (activeJobs.length === 1 && setFieldvalue && shiftType === "jobs") {
				// setLastSelectedJobId(activeJobs[0].id);
				// setLastSelectedHexColor(activeJobs[0].hexColor);
				setFieldvalue("jobId", activeJobs[0].id);
				setFieldvalue("hexColor", activeJobs[0].hexColor);
			}

			return activeJobs;
		},
		[
			allJobRoles,
			fetchFilterAndShiftDataStatus,
			filterAllWorkcenters,
			filterJobRoles,
			shiftType,
		]
	);

	const getNumberOfStaff = useCallback(
		(
			jobRole: string | null,
			locationId: string,
			workCenterId: string | number,
			startTime: string,
			endTime: string,
			shiftSkills?: any[],
			allowPartialSignups?: boolean
		) => {
			if (!locationId || !startTime || !endTime || !workCenterId) return;
			if (jobRole === "" && shiftSkills?.length === 0) return;

			dispatch(
				fetchMaxWorkersByLocationJob({
					locationId,
					jobId: jobRole === "" ? null : jobRole,
					workcenterId: Number(workCenterId),
					startDateTime: startTime,
					endDateTime: endTime,
					shiftSkills,
					isPartialSignupEnabled: allowPartialSignups,
				})
			);
		},
		[dispatch]
	);

	const handleWorkcenterChange = useCallback(
		(workcenterId: string, setFieldvalue: any) => {
			// setLastSelectedWorkcenterId(workcenterId);
			filterAndAutoSelectJobs(workcenterId, setFieldvalue);
		},
		[filterAndAutoSelectJobs]
	);

	useEffect(() => {
		const initValues = getInitValues({
			draftShiftDate,
			defaultDurationHrs,
			shiftPurposeId: 0,
			weekStartDay,
		});
		// if only one workcenter is available, set it as default
		if (filterAllWorkcenters.length === 1) {
			initValues.workCenterId = `${filterAllWorkcenters[0].id}`;
			initValues.locationId = filterAllWorkcenters[0].locationId;
			handleWorkcenterChange(filterAllWorkcenters[0].id.toString(), null);
			// if only one job is available, set it as default
			const jobIds = filterAllWorkcenters[0]?.jobIds?.split(",");
			const activeJobIds = allJobRoles
				.filter((job: JobRole) => job.isActive)
				.map((job: JobRole) => job.id);
			const availableJobIds = jobIds?.filter((jobId: string) =>
				activeJobIds?.includes(parseInt(jobId))
			);
			if (availableJobIds?.length === 1 && shiftType === "jobs") {
				initValues.jobId = availableJobIds[0];
				initValues.hexColor = allJobRoles.find(
					(job: JobRole) => job.id === parseInt(availableJobIds[0])
				)?.hexColor;
			}
			// if only one skill is available, set it as default
			if (filterAllSkills.length === 1 && shiftType === "skills") {
				initValues.shiftSkills = [
					{
						skillId: filterAllSkills[0].id,
						skillName: filterAllSkills[0].name,
						skillLevelId: allSkillLevels[defaultShiftsSkillLevel].id,
						level: allSkillLevels[defaultShiftsSkillLevel].level,
						id: filterAllSkills[0].id,
						name: filterAllSkills[0].name,
					},
				];
			}
		}
		setInitialShiftDateTimeValue(initValues);
		setSelectedDatesOfTheWeek(
			configureDatesOfTheWeek(new Date(initValues.startTime), weekStartDay)
		);
	}, [
		draftShiftDate,
		defaultDurationHrs,
		weekStartDay,
		filterAllWorkcenters,
		allJobRoles,
		shiftType,
		filterAllSkills,
		handleWorkcenterChange,
		allSkillLevels,
		defaultShiftsSkillLevel,
	]);

	// Trigger getNumberOfStaff when jobId or other dependencies change
	useEffect(() => {
		if (filteredJobs.length === 1 && currentLocationId) {
			const jobId = filteredJobs[0]?.id.toString();
			const locationId = currentLocationId;
			const { startTime, endTime, workCenterId, allowPartialSignups } =
				initialShiftDateTimeValues;
			if (jobId !== lastSelectedJobId) {
				getNumberOfStaff(
					jobId,
					locationId,
					workCenterId,
					new Date(startTime).toISOString(),
					new Date(endTime).toISOString(),
					[],
					allowPartialSignups
				);
				setLastSelectedJobId(jobId);
			}
		} else if (
			initialShiftDateTimeValues.shiftSkills.length > 0 &&
			currentLocationId
		) {
			const { startTime, endTime, workCenterId, allowPartialSignups } =
				initialShiftDateTimeValues;
			getNumberOfStaff(
				null,
				currentLocationId,
				workCenterId ?? null,
				new Date(startTime).toISOString(),
				new Date(endTime).toISOString(),
				initialShiftDateTimeValues.shiftSkills,
				allowPartialSignups
			);
		}
	}, [
		filteredJobs,
		getNumberOfStaff,
		initialShiftDateTimeValues,
		currentLocationId,
		lastSelectedJobId,
	]);

	const calculateHours = (
		startTime: string,
		endTime: string,
		numberOfWorkersNeeded: string
	) => {
		const { hours, minutes } = calculateShiftHoursAndMinutes(
			startTime,
			endTime,
			numberOfWorkersNeeded
		);
		setTotalHours(hours);
		setTotalMins(minutes);
	};

	const calculateTotalHoursFromWorkers = (
		formValues: FormValues,
		numberOfWorkersNeeded: string
	) => {
		const { startTime, endTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const calculateTotalHoursFromStartTime = (
		formValues: FormValues,
		startTime: string
	) => {
		const { numberOfWorkersNeeded, endTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const calculateTotalHoursFromEndTime = (
		formValues: FormValues,
		endTime: string
	) => {
		const { numberOfWorkersNeeded, startTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const isDisabled = (currentDate: Date, shiftStartDate: Date) => {
		return isAfter(shiftStartDate, currentDate) || false;
	};

	return [
		{
			initialValues,
			schema,
			initialShiftDateTimeValues,
			filteredJobs,
			selectedDatesOfTheWeek,
			totalHours,
			totalMins,
			shiftType,
		},
		{
			isDisabled,
			calculateTotalHoursFromEndTime,
			calculateTotalHoursFromStartTime,
			calculateTotalHoursFromWorkers,
			handleWorkcenterChange,
			getNumberOfStaff,
			setInitialShiftDateTimeValue,
			setSelectedDatesOfTheWeek,
			setTotalHours,
			setShiftType,
			setCurrentLocationId,
		},
	] as const;
};

export default useNewShiftDialog;
