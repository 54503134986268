export const LOCATIONS = "Location";
export const WORK_CENTERS = "Work Center";
export const JOB_ROLES = "Job Role";
export const SKILLS = "Skill";
export const STATUS = "Shift Status";
export const SHIFT_BY = "Shift Type";
export const PUBLISHED_BY = "Published By";
export const REPORTING_TO = "Reporting To";
export const PRIMARY_JOB_ROLES = "Primary Job";
export const WORKER_TYPES = "Worker Types";
export const SHIFT_PATTERNS = "Shift Patterns";
export const OVERTIME_AVAILABILITY = "Overtime Availability";
export const APPLIED_FILTERS_HEIGHT = 57;


