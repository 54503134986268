import { MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";

interface EndTimePickerProps {
	minShiftHourDuration: number;
	maxShiftHourDuration: number;
	defaultShiftHourDuration: number;
	values: {
		startTime: string;
		endTime: string;
		// Add other values if needed from the form
	};
	setFieldValue: (field: string, value: any) => void;
	errors: {
		endTime?: string;
		// Add other potential errors if needed
	};
	onChange: (value: any) => void;
	disabled?: boolean;
}

const EndTimePicker: React.FC<EndTimePickerProps> = ({
	minShiftHourDuration,
	maxShiftHourDuration,
	defaultShiftHourDuration,
	values,
	setFieldValue,
	errors,
	onChange,
	disabled,
}) => {
	// Generate time options based on start time and duration constraints
	const timeOptions = useMemo(() => {
		const options = [];
		const startTime = dayjs(values.startTime);

		// Generate options from min to max duration in 30-minute increments
		for (
			let hours = minShiftHourDuration;
			hours <= maxShiftHourDuration;
			hours += 0.5
		) {
			const endTime = startTime.add(hours, "hour");
			options.push({
				value: endTime.format("YYYY-MM-DDTHH:mm"), // Format to match the input value
				label: `${endTime.format("h:mm A")} (${hours} Hours)`,
				hours: hours,
			});
		}

		return options;
	}, [values.startTime, minShiftHourDuration, maxShiftHourDuration]);

	// Set default end time when start time changes
	useEffect(() => {
		if (values.startTime) {
			const defaultEndTime = dayjs(values.startTime)
				.add(defaultShiftHourDuration, "hour")
				.format("YYYY-MM-DDTHH:mm"); // Format to match the input value
			setFieldValue("endTime", defaultEndTime);
		}
	}, [values.startTime, defaultShiftHourDuration, setFieldValue]);

	// Find the closest valid option when the value doesn't exactly match
	const getCurrentValue = () => {
		const exactMatch = timeOptions.find(
			(option) => option.value === values.endTime
		);
		if (exactMatch) return values.endTime;

		// If no exact match, find the closest option
		const currentTime = dayjs(values.endTime);
		const closestOption = timeOptions.reduce((prev, curr) => {
			const prevDiff = Math.abs(dayjs(prev.value).diff(currentTime));
			const currDiff = Math.abs(dayjs(curr.value).diff(currentTime));
			return currDiff < prevDiff ? curr : prev;
		});

		return closestOption.value;
	};

	return (
		<TextField
			select
			fullWidth
			variant='filled'
			label='End Time'
			value={getCurrentValue()}
			onChange={(e) => {
				onChange(e.target.value);
			}}
			error={!!errors.endTime}
			helperText={errors.endTime}
			SelectProps={{
				MenuProps: {
					PaperProps: {
						sx: {
							maxHeight: 300,
							"& .MuiMenuItem-root": {
								padding: "12px 16px",
								"&:hover": {
									backgroundColor: "#f5f5f5",
								},
							},
						},
					},
				},
			}}
			disabled={disabled}
		>
			{timeOptions.map((option) => (
				<MenuItem
					key={option.value}
					value={option.value}
					sx={{
						fontSize: "14px",
						color: "rgba(0, 0, 0, 0.87)",
						fontFamily: "Roboto",
					}}
				>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};

export default EndTimePicker;
