// TODO - Type 'any' needs to be fixed.
import { Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
// import moment from "moment";
import { formatISO, parse } from "date-fns";
import React from "react";

import { formatDate } from "../../../../../utils/helpers";
import JobItem from "./../../job-role-item";

type Action =
	| { type: "selectShiftForPublish"; payload: number }
	| { type: "deselectShiftForPublish"; payload: number }
	| { type: "setShiftsForPublish"; payload: number[] };

const dayInMonthComparator = (v1: Date, v2: Date) =>
	v1.getTime() - v2.getTime();
export const columns = [
	{
		field: "shiftTime",
		headerName: "Shift Time",
		width: 230,
		valueGetter: (params: any) => {
			const dateRawString = params.row.date;
			const dateRawStartTimeString = params.row.time.start;
			// return new Date(
			// 	moment(
			// 		dateRawString + " " + dateRawStartTimeString,
			// 		"ddd, MMMM Do YYYY h:mm a",
			// 		true
			// 	).toISOString()
			// );
			const dayOfWeekMap: { [key: string]: number } = {
				Sun: 0,
				Mon: 1,
				Tue: 2,
				Wed: 3,
				Thu: 4,
				Fri: 5,
				Sat: 6,
			};

			const monthMap: { [key: string]: number } = {
				January: 0,
				February: 1,
				March: 2,
				April: 3,
				May: 4,
				June: 5,
				July: 6,
				August: 7,
				September: 8,
				October: 9,
				November: 10,
				December: 11,
			};

			// Extract and clean up the parts
			const [dayOfWeek, month, day, year] = dateRawString
				.replace(",", "")
				.split(" ");
			const dayOfMonth = parseInt(day.replace("th", ""), 10);
			// Combine into a single date string
			const combinedString = `${year}-${
				monthMap[month] + 1
			}-${dayOfMonth}T${dateRawStartTimeString}`;

			// Parse the combined string
			const parsedDate: Date = parse(
				combinedString,
				"yyyy-M-d'T'h:mm a",
				new Date()
			);
			const isoString: string = formatISO(parsedDate);

			return new Date(isoString);
		},
		sortComparator: dayInMonthComparator,
		renderCell: (params: any) => {
			const startTime = params.row.time.start;
			const endTime = params.row.time.end;
			const color = params.row.restrictFlexHours
				? !params.row.timeExceeded
					? "#747474"
					: "#bdbdbd"
				: "#747474";
			return (
				<div
					style={{
						display: "flex",
						padding: "10px",
						gap: "4px",
						alignItems: "center",
					}}
				>
					<Typography variant='subtitle2' sx={{ color: color }}>
						{formatDate({
							dateStr: params.row.shiftDateTime,
							formatType: "SHORT_DATE",
						})}
					</Typography>
					<Typography variant='body2' sx={{ color: color }}>
						{startTime} - {endTime}
					</Typography>
				</div>
			);
		},
	},
	{
		field: "shiftPurpose",
		headerName: "Shift Purpose",
		width: 150,
		valueGetter: (params: any) => params.row.shiftPurposeId,
		renderCell: (params: any) => {
			const color = params.row.restrictFlexHours
				? !params.row.timeExceeded
					? "#747474"
					: "#bdbdbd"
				: "#747474";
			return (
				<Typography variant='subtitle2' sx={{ color: color }}>
					{params.row.shiftPurpose}
				</Typography>
			);
		},
	},
	{
		field: "jobRole",
		headerName: "Job Role / Skills Required",
		width: 240,
		renderCell: (params: any) => {
			const color = params.row.restrictFlexHours
				? !params.row.timeExceeded
					? "#747474"
					: "#bdbdbd"
				: "#747474";
			const shiftSkills = params.row.skills;
			if (!params.row.jobRole) {
				const length = shiftSkills.length;
				const skills = shiftSkills
					.map((skill: { skillName: string }) => skill.skillName)
					.join(", ");
				if (length === 1)
					return (
						<Tooltip title={skills} arrow>
							<Typography variant='subtitle2' sx={{ color: color }}>
								{skills}
							</Typography>
						</Tooltip>
					);
				return (
					<Tooltip title={skills} arrow>
						<Box sx={{ wordBreak: "break-word" }} pt={1} pb={1}>
							<Typography variant='subtitle2' sx={{ color: color }}>
								{` ${shiftSkills[0].skillName}, `}
								<span
									style={{
										color: "#747474",
										cursor: "pointer",
										textDecoration: "underline",
									}}
								>{`+${shiftSkills.length - 1} skill(s)`}</span>
							</Typography>
						</Box>
					</Tooltip>
				);
			}
			return <JobItem jobRole={params.row.jobRole} />;
		},
	},
	{
		field: "workersRequired",
		headerName: "Required Workers",
		width: 150,
		renderCell: (params: any) => {
			const color = params.row.restrictFlexHours
				? !params.row.timeExceeded
					? "#747474"
					: "#bdbdbd"
				: "#747474";
			return (
				<Typography variant='subtitle2' sx={{ color: color }}>
					{params.row.workersRequired}
				</Typography>
			);
		},
	},
	{
		field: "hoursScheduled",
		headerName: "Total Hours",
		width: 150,
		renderCell: (params: any) => {
			const color = params.row.restrictFlexHours
				? !params.row.timeExceeded
					? "#747474"
					: "#bdbdbd"
				: "#747474";
			return (
				<Typography variant='subtitle2' sx={{ color: color }}>
					{params.row.hoursScheduled}
				</Typography>
			);
		},
	},
	{
		field: "createdByName",
		headerName: "Created By",
		width: 170,
		renderCell: (params: any) => {
			const color = params.row.restrictFlexHours
				? !params.row.timeExceeded
					? "#747474"
					: "#bdbdbd"
				: "#747474";
			return (
				<Typography variant='subtitle2' sx={{ color: color }}>
					{params.row.createdByName}
				</Typography>
			);
		},
	},
	{
		...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
		type: "string",
		renderCell: (params: any) => <></>,
	},
];

export const useStyles = makeStyles((theme) => ({
	actionBar: {
		marginTop: 8,
		marginBottom: 8,
	},
	btnLabel: {
		position: "relative",
	},
	progressIndicator: {
		position: "absolute",
		left: "50%",
	},
	dataGridColumn: {
		"& .MuiDataGrid-columnSeparator--sideRight": {
			display: "none !important",
		},
		border: "none !important",
	},
}));

export const reducer = (state: number[], action: Action): number[] => {
	switch (action.type) {
		case "selectShiftForPublish":
			return [...state, action.payload];
		case "deselectShiftForPublish":
			return state.filter((item) => {
				return item !== action.payload;
			});
		case "setShiftsForPublish":
			return [...action.payload];
		default:
			return state;
	}
};
