// TODO - Type 'any' needs to be fixed.
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import { differenceInMinutes } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";

import {
  ASSIGN_SHIFT,
  FASTEST,
  LOCATION,
  PRIMARYJOB,
  WORKCENTER,
} from "../../../../../../assets/constants";
import TimeRangeBar from "../../../../../../design/time-range-bar";
import { parseSeniority } from "../../../../../../utils/helpers";
import MultiLineTooltip from "../../../../../../utils/multi-line-tooltip";
import Highlight from "../../../../../Highlight";
import ShiftStatus from "../ShiftStatus";

const useShiftDetailsDialogColumns = (
  shiftMetadata: any,
  numberOfWorkers: number,
  workerAvailableForAssign: number,
  search: string,
  activeStep: string,
  shiftAllocationType: string,
  isOpenForOtherLocation: boolean,
  isWorkerSubTypeEnabled: boolean,
  isOverlappigSignupEnabled: boolean,
  isPartialSignupEnabled: boolean,
  overlappingSignupLimitMins: number,
  workers: any
) => {
  interface ftShift {
    shiftName: string;
    StartDateTime: string;
    EndDateTime: string;
  }

  const [columns, setColumns] = useState<any>([]);

  const getAvailableTimeRange = useCallback(
    (ftShifts: ftShift[] | null | undefined) => {
      if (!Array.isArray(ftShifts) || ftShifts.length === 0) {
        return {
          availableStartTime: new Date(
            shiftMetadata.startDateTime
          ).toISOString(),
          availableEndTime: new Date(shiftMetadata.endDateTime).toISOString(),
        };
      }

      let availableStartTime = new Date(shiftMetadata.startDateTime);
      let availableEndTime = new Date(shiftMetadata.endDateTime);

      ftShifts.forEach((ftShift) => {
        if (!ftShift?.StartDateTime || !ftShift?.EndDateTime) {
          return;
        }

        const ftStart = new Date(ftShift.StartDateTime);
        const ftEnd = new Date(ftShift.EndDateTime);

        // Adjust available time based on overlaps
        if (ftEnd > availableStartTime && ftEnd < availableEndTime) {
          if (
            differenceInMinutes(ftEnd, availableStartTime) <=
            overlappingSignupLimitMins
          ) {
            availableStartTime = ftEnd;
          }
        }
        if (ftStart > availableStartTime && ftStart < availableEndTime) {
          if (
            differenceInMinutes(availableEndTime, ftStart) <=
            Number(overlappingSignupLimitMins)
          ) {
            availableEndTime = ftStart;
          }
        }
      });

      return {
        ftAvailableStartTime: availableStartTime.toISOString(),
        ftAvailableEndTime: availableEndTime.toISOString(),
      };
    },
    [shiftMetadata, overlappingSignupLimitMins]
  );

  useEffect(() => {
    try {
      const workersColumn = {
        field: "workerName",
        headerName: "Worker",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <Typography variant="subtitle2">{`Worker Name (${
              activeStep === ASSIGN_SHIFT
                ? workerAvailableForAssign
                : numberOfWorkers
            })`}</Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            {params.row.errorMessage !== "" && activeStep === ASSIGN_SHIFT && (
              <Tooltip placement="right" title={params.row.errorMessage}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    marginRight: "8px",
                  }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#E44B48",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </Box>
              </Tooltip>
            )}
            <Tooltip title={params.row.workerName}>
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                <Highlight value={params.row.workerName} searchText={search} />
              </Typography>
            </Tooltip>
          </Box>
        ),
        minWidth: 180,
        flex: 1,
        checked: true,
        label: "Worker Name",
      };

      const workerTypeColumn = {
        field: "workerTypeText",
        headerName: "Worker Type",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <Typography variant="subtitle2">Worker Type</Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <Box>
            <Typography
              sx={{
                flexWrap: "wrap",
                wordBreak: "break-word",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {params.row.workerTypeText}
            </Typography>
          </Box>
        ),
        minWidth: 100,
        flex: 1,
        checked: true,
        label: "Worker Type",
      };

      const workerSubTypeColumn = {
        field: "workerSubTypeText",
        headerName: "Worker Sub Type",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <Typography variant="subtitle2">Worker Sub Type</Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <Box>
            <Typography
              sx={{
                flexWrap: "wrap",
                wordBreak: "break-word",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {params.row.workerSubType || "-"}
            </Typography>
          </Box>
        ),
        minWidth: 100,
        flex: 1,
        checked: false,
        label: "Worker Sub Type",
        valueGetter: (params: any) => params.row.workerSubType || "-",
      };

      const locationsColumn = {
        field: "location",
        headerName: "Location",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <Typography variant="subtitle2">Location</Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <Box>
            <Typography
              sx={{
                flexWrap: "wrap",
                wordBreak: "break-word",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {params.row.location}
            </Typography>
          </Box>
        ),
        minWidth: 130,
        flex: 1,
        checked:
          isOpenForOtherLocation &&
          (shiftAllocationType === LOCATION || shiftAllocationType === FASTEST),
        label: "Location",
      };

      const shiftTimeColumn = {
        field: "fullTimeShifts",
        headerName: "Shift Pattern (Schedule)",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <Typography variant="subtitle2">
              Shift Pattern (Schedule)
            </Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <MultiLineTooltip
            text={params?.row?.fullTimeShifts ?? "-"}
            searchText={search}
          />
        ),
        minWidth: 190,
        flex: 1,
        checked:
          (shiftAllocationType === FASTEST &&
            workers?.some(
              (worker: any) => worker?.workerType === "FullTime"
            )) ||
          shiftAllocationType === WORKCENTER,
        label: "Shift Pattern (Schedule)",
        valueGetter: (params: any) => params?.row?.fullTimeShifts ?? "-",
      };

      const seniorityColumn = {
        field: "seniorityYears",
        headerName: "Seniority",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Seniority
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <Box pl={1}>{params.row.seniorityYears}</Box>;
        },
        valueGetter: (params: any) =>
          parseSeniority(params.row.seniorityYears ?? ""),
        minWidth: 190,
        flex: 1,
        checked: shiftAllocationType !== FASTEST,
        label: "Seniority",
      };

      const reportingManager = {
        field: "reportingManager",
        headerName: "Reporting Manager",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Reporting Manager
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          let reportingManager = params.row.reportingManager ?? "-";
          if (reportingManager === "") {
            reportingManager = "-";
          }
          return (
            <Box
              pl={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                <Highlight value={reportingManager} searchText={search} />
              </Typography>
            </Box>
          );
        },
        valueGetter: (params: any) => params.row.reportingManager ?? "",
        minWidth: 180,
        flex: 1,
        checked: false,
        label: "Reporting Manager",
      };

      const statusColumn = {
        field: "Status",
        headerName: "Status",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Status
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return (
            <ShiftStatus
              label={params?.row?.status}
              reason={params?.row?.reason}
            />
          );
        },
        valueGetter: (params: any) => params.row.status,
        minWidth: 150,
        flex: 1,
        checked: true,
        label: "Status",
      };

      const overtimeColumn = {
        field: "overtime",
        headerName: "Overtime",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                YTD Overtime Hours
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <Box pl={1}>{params.row?.overtime}</Box>;
        },
        valueGetter: (params: any) => params.row?.overtimeMins,
        minWidth: 160,
        flex: 1,
        checked: false,
        label: "YTD Overtime Hours",
      };

      const workCenterColumn = {
        field: "workCenter",
        headerName: "Workcenter",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Work Center
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <Box pl={1}>{params.row?.workCenter?.name ?? "-"}</Box>;
        },
        valueGetter: (params: any) => params.row?.workCenter?.name,
        minWidth: 160,
        flex: 1,
        checked: true,
        label: "Work Center",
      };

      const primaryJobNameColumn = {
        field: "primaryJobName",
        headerName: "Primary Job",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  // paddingLeft: "8px",
                }}
              >
                Primary Job
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <MultiLineTooltip text={params?.row?.primaryJobName ?? "-"} />;
        },
        valueGetter: (params: any) => params.row?.primaryJobName,
        minWidth: 160,
        flex: 1,
        checked: shiftAllocationType === PRIMARYJOB,
        label: "Primary Job",
      };

      const workerIdColumn = {
        field: "companyEmployeeId",
        headerName: "Worker ID",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Worker ID
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <Box pl={1}>{params?.row?.companyEmployeeId}</Box>;
        },
        valueGetter: (params: any) => params?.row?.companyEmployeeId,
        minWidth: 160,
        flex: 1,
        checked: false,
        label: "Worker ID",
        sortComparator: (v1: any, v2: any) => {
          const getNumericPart = (
            value: string | undefined | null
          ): number | null => {
            if (!value) return null;
            const trimmedValue = value.trim(); // Trim spaces
            const match = trimmedValue.match(/^\d+$/); // Match fully numeric values
            return match ? parseInt(trimmedValue, 10) : null;
          };

          const isBlank = (value: string | undefined | null) =>
            value === null || value === undefined || value.trim() === "";

          // Handle blank/null/undefined values
          const blank1 = isBlank(v1);
          const blank2 = isBlank(v2);

          if (blank1 && blank2) return 0; // Both are blank
          if (blank1) return 1; // Blank values should come last
          if (blank2) return -1; // Blank values should come first

          const num1 = getNumericPart(v1);
          const num2 = getNumericPart(v2);

          // First compare by numerical value
          if (num1 !== null && num2 !== null) {
            return num1 - num2;
          }

          // If one is numeric and the other is not, prioritize the numeric one
          if (num1 !== null) return -1;
          if (num2 !== null) return 1;

          // Fall back to standard string comparison
          return v1.localeCompare(v2);
        },
      };

      const workerAvailabilityColumn = {
        field: "workerAvailability",
        headerName: "Worker Availability",
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  // paddingLeft: "8px",
                }}
              >
                Worker Availability
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          if (
              isPartialSignupEnabled ||
            params.row.workerType === "FullTime"
          ) {
            let availableStartTime = null;
            let availableEndTime = null;
            if (
              isPartialSignupEnabled &&
              params.row.workerShiftStartDateTime &&
              params.row.workerShiftEndDateTime
            ) {
              availableStartTime = params.row.workerShiftStartDateTime;
              availableEndTime = params.row.workerShiftEndDateTime;
            } else if (isOverlappigSignupEnabled) {
              const { ftAvailableStartTime, ftAvailableEndTime } =
                  getAvailableTimeRange(params.row.ftShifts);
              availableStartTime = ftAvailableStartTime;
              availableEndTime = ftAvailableEndTime;
            }
            if (availableStartTime && availableEndTime) {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "125px",
                    }}
                  >
                    <TimeRangeBar
                      totalStartTime={shiftMetadata.startDateTime}
                      totalEndTime={shiftMetadata.endDateTime}
                      availableStartTime={availableStartTime}
                      availableEndTime={availableEndTime}
                    />
                  </Box>
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  -
                </Box>
              );
            }
          }

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              -
            </Box>
          );
        },
        minWidth: 180,
        width: 180,
        flex: 1,
        checked: true,
        label: "Worker Availability",
      };

      const columns = [
        workersColumn,
        statusColumn,
        ...((isOverlappigSignupEnabled || isPartialSignupEnabled) ? [workerAvailabilityColumn] : []),
        workerTypeColumn,
        ...(isWorkerSubTypeEnabled ? [workerSubTypeColumn] : []),
        workCenterColumn,
        locationsColumn,
        shiftTimeColumn,
        overtimeColumn,
        seniorityColumn,
        reportingManager,
        primaryJobNameColumn,
        workerIdColumn,
      ];

      setColumns(columns);
    } catch (e) {
      console.log("Error setting up column headers", e);
    }
  }, [
    numberOfWorkers,
    search,
    shiftMetadata,
    activeStep,
    workerAvailableForAssign,
    shiftAllocationType,
    isOpenForOtherLocation,
    isWorkerSubTypeEnabled,
    overlappingSignupLimitMins,
    isOverlappigSignupEnabled,
    isPartialSignupEnabled,
    getAvailableTimeRange,
    workers,
  ]);
  return {
    columns,
    setColumns,
  };
};

export default useShiftDetailsDialogColumns;
