import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import {
  RadioList,
  SwitchShiftsFilter,
} from "../../../../export/shift-scheduler";
import { JOB, SKILLS } from "../../../assets/constants";

interface AvailabilityFiltersProps {
  shiftBySkills: boolean;
  shiftsByFilter: string;
  fetchFilterAndShiftDataStatus: string;
  value: any; // You can replace 'any' with a more specific type if available
  setValue: React.Dispatch<React.SetStateAction<any>>; // You can replace 'any' with a more specific type if available
  height: number;
  allSkills: any[]; // You can replace 'any' with a more specific type if available
  allJobRoles: any[]; // You can replace 'any' with a more specific type if available
}

const AvailabilityFilters: React.FC<AvailabilityFiltersProps> = ({
  shiftBySkills,
  shiftsByFilter,
  fetchFilterAndShiftDataStatus,
  value,
  setValue,
  height,
  allSkills,
  allJobRoles,
}) => {
  const [filteredJobRoles, setFilteredJobRoles] = useState<any[]>([]);
  const [filteredSkills, setFilteredSkills] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    try {
      if (allJobRoles && allJobRoles.length > 0) {
        if (search === "") {
          setFilteredJobRoles(allJobRoles);
        } else {
          let result = allJobRoles.filter((x) =>
            x.name.toLowerCase().includes(search.toLowerCase())
          );
          result = result.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          setFilteredJobRoles(result);
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [allJobRoles, search]);

  useEffect(() => {
    try {
      if (allSkills && allSkills.length > 0) {
        if (search === "") {
          setFilteredSkills(allSkills);
        } else {
          let result = allSkills.filter((x) =>
            x.name.toLowerCase().includes(search.toLowerCase())
          );
          result = result.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          setFilteredSkills(result);
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [allSkills, search]);

  useEffect(() => {
      try {
          setValue(null);
          setSearch("");
      } catch (e) {
          // Do nothing
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftsByFilter]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "282px",
        height: height,
      }}
    >
      {shiftBySkills && (
        <Box
          sx={{
            borderRight: "1px  rgba(0,0,0,0.12) solid",
            height: "57px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SwitchShiftsFilter shiftsByFilter={shiftsByFilter} />
        </Box>
      )}
      <Box
        sx={{
          borderRight: "1px  rgba(0,0,0,0.12) solid",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "57px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              minWidth: "140px",
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            {shiftsByFilter === JOB && `Job Roles (${filteredJobRoles.length})`}
            {shiftsByFilter === SKILLS && `Skills (${filteredSkills.length})`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            height: "57px",
            flex: 1,
          }}
        >
          <TextField
            hiddenLabel
            value={search}
            onChange={(e) => {
              const text = e.target.value;
              setSearch(text);
            }}
            placeholder={"Search"}
            variant="filled"
            size="small"
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <SearchIcon
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "rgba(0,0,0,0.56)",
                  }}
                />
              ),
              endAdornment:
                search !== "" ? (
                  <ClearIcon
                    onClick={() => {
                      setSearch("");
                    }}
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "rgba(0,0,0,0.56)",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                ),
            }}
            sx={{
              width: "100%",
              border: "1px rgba(0,0,0,0.12) solid ",
              borderRadius: "4px",
              input: {
                paddingLeft: "8px",
                color: "rgba(0,0,0,0.87)",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0.4px",
              },
            }}
          />
        </Box>
      </Box>
      {fetchFilterAndShiftDataStatus === "pending" ? (
        <Box sx={{ display: "flex", margin: "16px" }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box
          key={`list-${shiftsByFilter}`}
          sx={{
            flex: 1,
            overflowY: "auto",
            borderRadius: "0px 0px 0px 15px",
            borderRight: "1px  rgba(0,0,0,0.12) solid",
          }}
        >
          {shiftsByFilter === JOB && (
            <RadioList
              value={value}
              setValue={(id: number) => setValue(id)}
              items={filteredJobRoles}
              search={search}
            />
          )}
          {shiftsByFilter === SKILLS && (
            <RadioList
              value={value}
              setValue={(id: number) => setValue(id)}
              items={filteredSkills}
              search={search}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AvailabilityFilters;
