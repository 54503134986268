// TODO - Type 'any' needs to be fixed.
import { addDays, endOfDay, endOfWeek, format, startOfWeek } from "date-fns";
import { FormikValues } from "formik";
import { Dispatch, SetStateAction, useContext } from "react";

import { requestShiftAction } from "../../../../../../features/worker-requests/store/worker-requests-slice";
import {
	JOB_ROLES,
	SKILLS as SKILLS_FILTER,
} from "../../../../../filters/constants";
import {
	AppliedFilter,
	FilterContext,
} from "../../../../../filters/filter-context";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import { createRepeatDraftShiftAsync } from "../../../../store/scheduler-slice";
import { getDayNumber } from "../../copy-shifts-dialog/utils";
import { configureDatesOfTheWeek, getDaysOfWeek } from "../utils";

type SkillsProps = {
	shiftId?: number;
	skillId: number;
	skillLevelId?: number;
	skillName: string;
	skillLevel?: string;
	level: number;
};

type UseNewShiftDialogForm = {
	setToNearestTime: (dateString: string) => string;
	dispatch: any;
	createDraftShiftAsync: (payload: any) => void;
	setSelectedDatesOfTheWeek: ((dates: any) => void) | undefined;
	calculateTotalHoursFromStartTime:
		| ((values: any, time: string) => void)
		| undefined;
	calculateTotalHoursFromEndTime:
		| ((values: any, time: string) => void)
		| undefined;
	isShiftSwap: boolean | undefined;
};

type FormValues = {
	setFieldValue: (a: string, b: any) => void;
	values: FormikValues;
};

type OnEndTimeChangeParams = {
	e: React.ChangeEvent<HTMLInputElement>; // Assuming e is an input change event
	setFieldValue: (a: string, b: any) => void; // Using Formik's setFieldValue function with FormikValues type
	values: FormikValues; // FormikValues type for values
};

type Workcenter = {
	location: {
		id: string;
		name: string;
	};
	locationName: string;
	locationId: string;
};

export const UseNewShiftDialogForm = ({
	setToNearestTime,
	dispatch,
	createDraftShiftAsync,
	setSelectedDatesOfTheWeek,
	calculateTotalHoursFromStartTime,
	calculateTotalHoursFromEndTime,
	isShiftSwap,
}: UseNewShiftDialogForm) => {
	const {
		formData,
		userId,
		filterJobRoles,
		isShiftPurposeEnabled,
		weekStartDay,
	} = useSchedulerData();

	let withinFilterContext = false;
	let setAppliedFilters:
		| Dispatch<SetStateAction<AppliedFilter[]>>
		| ((arg0: { (prevFilters: any): any[]; (prevFilters: any): any[] }) => void)
		| undefined = undefined;
	const filterContext = useContext(FilterContext);
	if (filterContext) {
		withinFilterContext = true;
		setAppliedFilters = filterContext.setAppliedFilters;
	}

	const onFormSubmit = (values: any, actions: any) => {
		const { setSubmitting } = actions;
		if (isShiftSwap) {
			const flexShiftDetails = {
				endDateTime: new Date(values.endTime),
				startDateTime: new Date(values.startTime),
				CreatedBy: userId,
				IsPublished: false,
				jobId: values.jobId === "" ? null : values.jobId,
				workCenterId: values.workCenterId,
				locationId: values.locationId,
				numberOfWorkersNeeded: values.numberOfWorkersNeeded,
				shiftSkills: values.shiftSkills,
				shiftNote: values.shiftNote,
				shiftPurposeId: isShiftPurposeEnabled ? values.shiftPurposeId : null,
				IsPartialSignupEnabled: values.allowPartialSignups,
			};
			const payload = {
				...formData,
				flexShiftDetails: flexShiftDetails,
			};
			dispatch(requestShiftAction({ ...payload, setSubmitting }));
			return;
		}
		if (withinFilterContext && setAppliedFilters) {
			const id = values.jobId;
			if (id === "" || id === 0) {
				const newFilter = {
					type: SKILLS_FILTER,
					timeAdded: Date.now(),
					data: values.shiftSkills.map((skill: SkillsProps) => {
						return {
							id: skill.skillId,
							name: skill.skillName,
							timeAdded: Date.now(),
						};
					}),
				};
				setAppliedFilters((prevFilters) => {
					if (prevFilters.length === 0) {
						return [];
					}
					const newFilters = [...prevFilters];
					const filterIndex = newFilters.findIndex(
						(filter) => filter.type === SKILLS_FILTER
					);
					if (filterIndex > -1) {
						const existingData = newFilters[filterIndex].data;
						if (
							!existingData.find(
								(data: { id: any }) => data.id === newFilter.data[0].id
							)
						) {
							newFilters[filterIndex].data.push(newFilter.data[0]);
						}
					} else {
						newFilters.push(newFilter);
					}
					return newFilters;
				});
			} else {
				const newFilter = {
					type: JOB_ROLES,
					timeAdded: Date.now(),
					data: filterJobRoles
						.filter((job: any) => job.id === id)
						.map((job: any) => {
							return {
								id: job.id,
								name: job.name,
								timeAdded: Date.now(),
							};
						}),
				};
				setAppliedFilters((prevFilters) => {
					if (prevFilters.length === 0) {
						return [];
					}
					const newFilters = [...prevFilters];
					const filterIndex = newFilters.findIndex(
						(filter) => filter.type === JOB_ROLES
					);
					if (filterIndex > -1) {
						const existingData = newFilters[filterIndex].data;
						if (
							!existingData.find(
								(data: { id: any }) => data.id === newFilter.data[0].id
							)
						) {
							newFilters[filterIndex].data.push(newFilter.data[0]);
						}
					} else {
						newFilters.push(newFilter);
					}
					return newFilters;
				});
			}
		}

		if (values.repeatShift) {
			const repeatData = {
				daysOfWeek: getDaysOfWeek(values.repeatOn),
				endDate:
					values.endType === "OnDate" ? new Date(values.repeatEndDate) : null,
				timeZoneOffset: new Date().getTimezoneOffset() * -1,
				weekStartDateTime: addDays(
					startOfWeek(new Date(values.startTime), {
						weekStartsOn: getDayNumber(weekStartDay),
					}),
					0
				).toISOString(),
				weekEndDateTime: addDays(
					endOfWeek(new Date(values.startTime), {
						weekStartsOn: getDayNumber(weekStartDay),
					}),
					0
				).toISOString(),
			};
			dispatch(
				createRepeatDraftShiftAsync({
					...values,
					...repeatData,
					setSubmitting,
				})
			);
			return;
		}
		dispatch(createDraftShiftAsync({ ...values, setSubmitting }));
	};

	const onStartTimeChange = (dateString: string, formValues: FormValues) => {
		const { setFieldValue, values } = formValues;
		setFieldValue("startTime", setToNearestTime(dateString));
		if (setSelectedDatesOfTheWeek)
			setSelectedDatesOfTheWeek(
				configureDatesOfTheWeek(
					new Date(setToNearestTime(dateString)),
					weekStartDay
				)
			);
		// Clearing all prior repeat settings
		setFieldValue("repeatOn[0]", false);
		setFieldValue("repeatOn[1]", false);
		setFieldValue("repeatOn[2]", false);
		setFieldValue("repeatOn[3]", false);
		setFieldValue("repeatOn[4]", false);
		setFieldValue("repeatOn[5]", false);
		setFieldValue("repeatOn[6]", false);
		// Selecting day on date change
		setFieldValue(
			`repeatOn[${new Date(setToNearestTime(dateString)).getDay()}]`,
			true
		);
		if (calculateTotalHoursFromStartTime)
			calculateTotalHoursFromStartTime(values, dateString);
	};

	const onEndTimeChange = (dateString: string, formValues: FormValues) => {
		const { setFieldValue, values } = formValues;
		setFieldValue("endTime", setToNearestTime(dateString));
		if (calculateTotalHoursFromEndTime)
			calculateTotalHoursFromEndTime(values, dateString);
	};

	const getLocationWorkcenters = (workcenters: Workcenter[]): Workcenter[] => {
		const locObj: Record<string, Workcenter[]> = {};
		workcenters.forEach((wc) => {
			if (locObj[wc.location.id]) {
				locObj[wc.location.id].push({
					...wc,
					locationName: wc.location.name,
					locationId: wc.location.id,
				});
			} else {
				locObj[wc.location.id] = [
					{ ...wc, locationName: wc.location.name, locationId: wc.location.id },
				];
			}
		});
		const result: Workcenter[] = [];
		Object.values(locObj).forEach((loc: any) =>
			loc.forEach((wc: any) => result.push(wc))
		);
		return result;
	};

	return [
		{},
		{
			onFormSubmit,
			onEndTimeChange,
			onStartTimeChange,
			getLocationWorkcenters,
		},
	];
};
