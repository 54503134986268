const countryData = [
    { code: "US", name: "USA", dialCode: "+1", flag: "🇺🇸" },
    { code: "BR", name: "Brazil", dialCode: "+55", flag: "🇧🇷" },
    { code: "CA", name: "Canada", dialCode: "+1", flag: "🇨🇦" },
    { code: "GB", name: "UK", dialCode: "+44", flag: "🇬🇧" },
    { code: "IN", name: "India", dialCode: "+91", flag: "🇮🇳" },
    { code: "MX", name: "Mexico", dialCode: "+52", flag: "🇲🇽" },
    { code: "PH", name: "Philippines", dialCode: "+63", flag: "🇵🇭" },
    { code: "OM", name: "Oman", dialCode: "+968", flag: "🇴🇲" },
];

export default countryData;