import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
	Box,
	Button,
	Divider,
	Popover,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
// import { LocalizationProvider } from "@mui/x-date-pickers-6/LocalizationProvider";
import { LocalizationProvider } from "@mui/x-date-pickers-6";
import { AdapterDayjs } from "@mui/x-date-pickers-6/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers-6/DateCalendar";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

// Add interface for props
interface TimePickerProps {
	label: string;
	value?: string;
	onChange?: (value: string) => void;
	isError?: boolean;
	helperText?: string;
	disabled?: boolean;
}

const DateTimePicker: React.FC<TimePickerProps> = ({
	label,
	value,
	onChange,
	isError,
	helperText,
	disabled,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	// Initialize states from the value prop if provided
	const initialDate = value ? dayjs(value) : dayjs();
	const [selectedDate, setSelectedDate] = useState(initialDate);
	const [hour, setHour] = useState(initialDate.format("h"));
	const [minutes, setMinutes] = useState(initialDate.format("mm"));
	const [period, setPeriod] = useState(initialDate.format("A"));

	// Add these new states
	const [initialStateValues, setInitialStateValues] = useState({
		date: initialDate,
		hour: initialDate.format("h"),
		minutes: initialDate.format("mm"),
		period: initialDate.format("A"),
	});

	// Update parent component when time changes
	const updateParentValue = () => {
		if (onChange) {
			const newDate = selectedDate
				.hour(
					period === "PM" && hour !== "12"
						? parseInt(hour) + 12
						: parseInt(hour)
				)
				.minute(parseInt(minutes));
			onChange(newDate.format());
		}
	};

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (disabled) return;
		setAnchorEl(event.currentTarget);
		setInitialStateValues({
			date: selectedDate,
			hour: hour,
			minutes: minutes,
			period: period,
		});
	};

	const closePopover = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const handleCancel = () => {
		setSelectedDate(initialStateValues.date);
		setHour(initialStateValues.hour);
		setMinutes(initialStateValues.minutes);
		setPeriod(initialStateValues.period);
		closePopover();
	};

	const handleOk = () => {
		updateParentValue();
		closePopover();
	};

	const incrementHour = () => {
		const currentHour = parseInt(hour) || 0;
		const newHour = currentHour >= 12 ? 1 : currentHour + 1;
		setHour(newHour.toString());
	};

	const decrementHour = () => {
		const currentHour = parseInt(hour) || 0;
		const newHour = currentHour <= 1 ? 12 : currentHour - 1;
		setHour(newHour.toString());
	};

	const toggleMinutes = () => {
		const newMinutes = minutes === "00" ? "30" : "00";
		setMinutes(newMinutes);
	};

	const handlePeriodChange = (
		_: React.MouseEvent<HTMLElement>,
		newPeriod: string
	) => {
		if (newPeriod !== null) {
			setPeriod(newPeriod);
		}
	};

	// Update local state when value prop changes
	useEffect(() => {
		if (value) {
			const date = dayjs(value);
			setSelectedDate(date);
			setHour(date.format("h"));
			setMinutes(date.format("mm"));
			setPeriod(date.format("A"));
		}
	}, [value]);

	return (
		<>
			<TextField
				label={label}
				value={`${selectedDate.format(
					"MM/DD/YYYY"
				)}, ${hour}:${minutes} ${period}`}
				onClick={handleOpen}
				InputProps={{
					endAdornment: <CalendarMonthOutlinedIcon />,
					readOnly: true,
				}}
				disabled={disabled}
				fullWidth
				variant='filled'
				error={isError}
				helperText={helperText}
			/>

			<Popover
				open={open}
				anchorEl={anchorEl}
				disableEscapeKeyDown
				disableAutoFocus
				disableEnforceFocus
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				PaperProps={{
					sx: {
						p: 2,
						boxShadow: 3,
					},
				}}
			>
				<Box sx={{ display: "flex", gap: 2 }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DateCalendar
							value={selectedDate}
							onChange={(newDate) => {
								setSelectedDate(newDate);
							}}
							sx={{
								"& .MuiPickersCalendarHeader-root": {
									paddingLeft: "8px",
									paddingRight: "8px",
									marginTop: "8px",
								},
								"& .MuiPickersCalendarHeader-label": {
									fontSize: "1rem",
								},
								"& .MuiDayCalendar-weekDayLabel": {
									fontSize: "0.875rem",
								},
								"& .MuiPickersDay-root": {
									fontSize: "0.875rem",
									width: 36,
									height: 36,
									"&.Mui-selected": {
										backgroundColor: "#2F4D8B",
										color: "#fff",
									},
								},
							}}
						/>
					</LocalizationProvider>
					<Divider orientation='vertical' flexItem />
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							padding: 2,
							position: "relative",
							minWidth: "280px",
						}}
					>
						<Typography
							sx={{
								position: "absolute",
								top: 16,
								left: 16,
								color: "rgba(0, 0, 0, 0.6)",
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								lineHeight: "16px",
								letterSpacing: "0.5px",
							}}
						>
							Enter time
						</Typography>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 2,
								mt: 8,
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<IconButton
									size='medium'
									onClick={incrementHour}
									sx={{
										padding: "8px",
										"&:hover": {
											backgroundColor: "#e8f0fe",
										},
									}}
								>
									<KeyboardArrowUpIcon sx={{ fontSize: 28 }} />
								</IconButton>
								<Typography
									variant='h4'
									sx={{
										minWidth: "60px",
										textAlign: "center",
										padding: "8px 12px",
										backgroundColor: "#e8f0fe",
										borderRadius: "4px",
										userSelect: "none",
										fontWeight: "500",
									}}
								>
									{hour.padStart(2, "0")}
								</Typography>
								<Typography
									sx={{
										fontSize: "12px",
										fontWeight: "500",
										color: "rgba(0, 0, 0, 0.6)",
										mt: 1,
									}}
								>
									Hours
								</Typography>
								<IconButton
									size='medium'
									onClick={decrementHour}
									sx={{
										padding: "8px",
										"&:hover": {
											backgroundColor: "#e8f0fe",
										},
									}}
								>
									<KeyboardArrowDownIcon sx={{ fontSize: 28 }} />
								</IconButton>
							</Box>
							<Typography variant='h3'>:</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<IconButton
									size='medium'
									onClick={toggleMinutes}
									sx={{
										padding: "8px",
										"&:hover": {
											backgroundColor: "#e8f0fe",
										},
									}}
								>
									<KeyboardArrowUpIcon sx={{ fontSize: 28 }} />
								</IconButton>
								<Typography
									variant='h4'
									sx={{
										minWidth: "60px",
										textAlign: "center",
										padding: "8px 12px",
										backgroundColor: "#e8f0fe",
										borderRadius: "4px",
										userSelect: "none",
										fontWeight: "500",
									}}
								>
									{minutes}
								</Typography>
								<Typography
									sx={{
										fontSize: "12px",
										fontWeight: "500",
										color: "rgba(0, 0, 0, 0.6)",
										mt: 1,
									}}
								>
									Minutes
								</Typography>
								<IconButton
									size='medium'
									onClick={toggleMinutes}
									sx={{
										padding: "8px",
										"&:hover": {
											backgroundColor: "#e8f0fe",
										},
									}}
								>
									<KeyboardArrowDownIcon sx={{ fontSize: 28 }} />
								</IconButton>
							</Box>
							<ToggleButtonGroup
								value={period}
								exclusive
								onChange={handlePeriodChange}
								size='small'
								orientation='vertical'
								sx={{ height: "fit-content" }}
							>
								<ToggleButton
									value='AM'
									sx={{
										"&.Mui-selected": {
											backgroundColor: "#e8f0fe",
										},
										borderRadius: "4px !important",
										marginBottom: "4px !important",
									}}
								>
									AM
								</ToggleButton>
								<ToggleButton
									value='PM'
									sx={{
										"&.Mui-selected": {
											backgroundColor: "#e8f0fe",
										},
										borderRadius: "4px !important",
									}}
								>
									PM
								</ToggleButton>
							</ToggleButtonGroup>
						</Box>
					</Box>
				</Box>
				<Divider />
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						mt: 2,
						gap: 1, // Add gap between buttons
					}}
				>
					<Button
						onClick={handleCancel}
						variant='text'
						sx={{
							color: "text.secondary",
						}}
					>
						Cancel
					</Button>
					<Button onClick={handleOk} variant='text' sx={{ color: "#2F4D8B" }}>
						OK
					</Button>
				</Box>
			</Popover>
		</>
	);
};

export default DateTimePicker;
