import { Box, Paper, Typography } from "@mui/material";
import { differenceInMinutes, parseISO } from "date-fns";
import React from "react";

interface TimeRangeBarProps {
	totalStartTime: string;
	totalEndTime: string;
	availableStartTime: string;
	availableEndTime: string;
}

const TimeRangeBar: React.FC<TimeRangeBarProps> = ({
	totalStartTime,
	totalEndTime,
	availableEndTime,
	availableStartTime,
}) => {
	// Parse ISO strings to Date objects
	const totalStart = parseISO(totalStartTime);
	const totalEnd = parseISO(totalEndTime);
	const acceptedStart = parseISO(availableStartTime);
	const acceptedEnd = parseISO(availableEndTime);

	// Calculate durations in minutes
	const totalDuration = differenceInMinutes(totalEnd, totalStart);
	const acceptedStartOffset = differenceInMinutes(acceptedStart, totalStart);
	const acceptedDuration = differenceInMinutes(acceptedEnd, acceptedStart);

	// Calculate positions as percentages
	const acceptedStartPercent = (acceptedStartOffset / totalDuration) * 100;
	const acceptedDurationPercent = (acceptedDuration / totalDuration) * 100;

	// Format display times (using Intl.DateTimeFormat for display only)
	const formatTimeDisplay = (dateObj: Date) => {
		return new Intl.DateTimeFormat("en-US", {
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		}).format(dateObj);
	};

	return (
		<Box
			sx={{
				width: "100%",
				textAlign: "center",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100%",
			}}
		>
			<Typography
				sx={{
					mb: 1,
					fontSize: "14px",
					fontWeight: 400,
					alignSelf: "center",
				}}
			>
				{formatTimeDisplay(acceptedStart)} - {formatTimeDisplay(acceptedEnd)}
			</Typography>

			<Paper
				elevation={0}
				sx={{
					height: "4px",
					width: "100%",
					display: "flex",
					overflow: "hidden",
					backgroundColor: "#ACB8D1",
					position: "relative",
					borderRadius: 0,
				}}
			>
				<Box
					sx={{
						position: "absolute",
						left: `${acceptedStartPercent}%`,
						width: `${acceptedDurationPercent}%`,
						height: "100%",
						backgroundColor: "#2F4D8B",
					}}
				/>
			</Paper>
		</Box>
	);
};

export default TimeRangeBar;
