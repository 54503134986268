import { Button, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import LoadingButton from "../../../../../design/wilya-loading-button";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import { deleteDraftShiftAsync } from "../../../store/scheduler-slice";

export default function DeleteDraftDialog({
	open,
	onClose,
	title,
	shiftId,
}: {
	open: boolean;
	onClose: () => void;
	title?: string;
	shiftId: number;
}) {
	const dispatch = useDispatch();

	const { deleteDraftShiftAsyncStatus } = useSchedulerData();

	const confirmDeleteShift = () => {
		const o = {
			shiftId: shiftId,
			cancelReasonId: null,
			otherCancelReason: "",
			cancelMsg: "",
		};
		dispatch(deleteDraftShiftAsync(o));
	};

	useEffect(() => {
		if (deleteDraftShiftAsyncStatus === "fulfilled") {
			onClose();
		}
	}, [deleteDraftShiftAsyncStatus, onClose]);

	return (
		<Dialog
			open={open}
			// TransitionComponent={Transition}
			keepMounted
			onClose={() => onClose()}
		>
			<DialogTitle>Delete Draft Shift </DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-slide-description'>
				Workers have not been notified of this Draft Shift. The Shift will be permanently deleted.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant='outlined'>
					CANCEL
				</Button>
				<LoadingButton
					onClick={confirmDeleteShift}
					color='error'
					variant='outlined'
					loading={deleteDraftShiftAsyncStatus === "pending"}
					disabled={deleteDraftShiftAsyncStatus === "pending"}
				>
					DELETE
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
