//import Button from "@gigandtake/button";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";

import Button from "../../../../../design/button";
import { formatDate } from "../../../../../utils/helpers";

type EditActionButtonsProps = {
  handleClose: (() => void) | undefined;
  isValid: boolean;
  dirty: boolean;
  isSubmitting: boolean;
  isDisabled: boolean;
  createdByName?: string;
  createdDate?: string;
};

const EditActionButtons: React.FC<EditActionButtonsProps> = ({
  handleClose,
  isValid,
  dirty,
  isSubmitting,
  isDisabled,
  createdByName = "",
  createdDate,
}) => {
  const editDraftShiftErrorMessage = useSelector(
    (state: any) => state.supervisor?.editDraftShift?.errorMessage
  );
  const userRoles = useSelector((state: any) => state.user.userData.userRoles);

  return (
    <Box
      display="flex"
      mt={6}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
    >
      <Box style={{ marginRight: "24px" }}>
        <Typography
            variant="body2"
            sx={{
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
              color: "#00000099",
            }}
        >
          Created by {createdByName} on{" "}
          {createdDate &&
              `
											${formatDate({
                dateStr: createdDate ?? "",
                formatType: "CAPTION_DATE",
              })} at ${formatDate({
                dateStr: createdDate ?? "",
                formatType: "TIME",
              })}
										`}
        </Typography>
      </Box>
      <Box display="flex">
        <Button color="primary" variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
            color="primary"
            type="submit"
            customStyles={{
              marginLeft: "16px",
            }}
            disabled={
              (
                  Array.isArray(userRoles)
                      ? userRoles.includes("ReadOnly")
                      : false
              )
                  ? true
                  : !isValid || !dirty || isSubmitting || isDisabled
            }
        >
          {isSubmitting ? (
              <div style={{ position: "relative" }}>
                <CircularProgress
                    size={25}
                    style={{ position: "absolute", left: "50%" }}
                />{" "}
                Saving...
              </div>
          ) : (
              "Save Shift"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default EditActionButtons;
