import { Grid } from "@mui/material";
import React from "react";

import {
	ShiftAllocationRules,
	ShiftAllocationRulesEmpty,
	//ShiftForceAssignment,
	ShiftScheduleRules,
	Teams,
} from "../../../../../../export/gat-admin";
import SectionWrapper from "../../../section-wrapper";
import SectionActions from "../../section-actions";
import useShiftSettingsController from "./controllers/use-shift-settings-controller";
import FlexSignup from "./flex-signup";
import JobExpiration from "./job-expiration/job-expiration";
import MinShiftsGap from "./min-shifts-gap";
import OverlapShifts from "./overlap-shifts";
import ShiftCancellation from "./shift-cancellation";
import ShiftPurpose from "./shift-purpose";
import ShiftSwapRules from "./shift-swap-rules";

interface IShiftSettingsProps {
	locationData: any;
	creatingNewLocation: boolean;
	companySettings?: any;
	setCompanySettings?: any;
	entity: string;
}
const ShiftSettings: React.FC<IShiftSettingsProps> = ({
	locationData,
	creatingNewLocation,
	companySettings,
	setCompanySettings,
	entity,
}) => {
	const [
		{
			shiftSettings,
			newShiftSettings,
			shiftSettingsLocationData,
			newShiftSettingsLocationData,
			loading,
			overlapWorkcenters,
			newOverlapWorkcenters,
			partialShiftWorkcenters,
			newPartialShiftWorkcenters,
		},
		{
			setNewShiftSettings,
			handleSave,
			setOverlapWorkcenters,
			setNewOverlapWorkcenters,
			setNewShiftSettingsLocationData,
			setPartialShiftWorkcenters,
			setNewPartialShiftWorkcenters,
		},
	]: any = useShiftSettingsController(entity, locationData);

	return (
		<SectionWrapper
			title='Shift Settings'
			subtitle='Worker shift related settings.'
		>
			<Grid
				container
				sx={{
					paddingTop: "16px",
					paddingBottom: "16px",
				}}
			>
				<Grid item xs={12}>
					{entity !== "location" ? (
						<>
							<ShiftSwapRules
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
							/>
							<ShiftPurpose
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
							/>
							<ShiftCancellation
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
							/>
							{/* <ShiftForceAssignment
								newShiftSettings={newShiftSettings}
							// setNewShiftSettings={setNewShiftSettings}
							/> */}
							<JobExpiration
								companySettings={companySettings}
								setCompanySettings={setCompanySettings}
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
							/>
						</>
					) : null}
					{!creatingNewLocation && entity === "location" ? (
						<>
							<ShiftScheduleRules locationData={locationData} />
							<Teams />
							{Object.keys(newShiftSettings).length === 0 ? (
								<ShiftAllocationRulesEmpty />
							) : (
								<ShiftAllocationRules
									locationData={newShiftSettingsLocationData}
									setLocationData={setNewShiftSettingsLocationData}
									creatingNewLocation={creatingNewLocation}
								/>
							)}
							<OverlapShifts
								overlapWorkcenters={overlapWorkcenters}
								newOverlapWorkcenters={newOverlapWorkcenters}
								setNewOverlapWorkcenters={setNewOverlapWorkcenters}
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
								loading={loading}
							/>
							<MinShiftsGap
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
								loading={loading}
							/>
							<FlexSignup
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
								loading={loading}
								partialShiftWorkcenters={partialShiftWorkcenters}
								newPartialShiftWorkcenters={newPartialShiftWorkcenters}
								setNewPartialShiftWorkcenters={setNewPartialShiftWorkcenters}
							/>
						</>
					) : null}
				</Grid>
			</Grid>
			<SectionActions
				oldData={{
					...shiftSettings,
					...shiftSettingsLocationData,
					...[...overlapWorkcenters, ...partialShiftWorkcenters],
				}}
				newData={{
					...newShiftSettings,
					...newShiftSettingsLocationData,
					...[...newOverlapWorkcenters, ...newPartialShiftWorkcenters],
				}}
				resetChanges={() => {
					setNewShiftSettings(shiftSettings);
					setNewShiftSettingsLocationData(shiftSettingsLocationData);
					setNewOverlapWorkcenters(overlapWorkcenters);
					setNewPartialShiftWorkcenters(partialShiftWorkcenters);
				}}
				handleSave={handleSave}
				//status={updateCompanySettingsStatus}
				loading={loading}
			/>
		</SectionWrapper>
	);
};

export default ShiftSettings;
