import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
	updateAllowedWorkCentersForShifts,
	updateCompanySettings,
	updateLocationData,
} from "../../../../../../../export/gat-admin";
import useGatAdminConfigData from "../../../../../../../ts/components/gat-admin/controllers/use-gat-admin-config-data";
import { findDifferentKeys } from "../../../../utils";

const shiftObj: any = {
	swapEnabled: "shift.swapEnabled",
	swapApprovalRequired: "shift.swapApprovalRequired",
	cancelEnabled: "shift.cancelEnabled",
	forceCancelEnabled: "shift.forceCancelEnabled",
	cancelWindowBeforeStartDays: "shift.cancelWindowBeforeStartDays",
	cancelWindowAfterAcceptHrs: "shift.cancelWindowAfterAcceptHrs",
	jobExpiryEnabled: "clientApp.jobExpiryEnabled",
	minWorkHoursRequired: "jobExpiry.minWorkHoursRequired",
	minWorkHoursDays: "jobExpiry.minWorkHoursDays",
	shiftPurpose: "clientApp.showShiftPurpose.enabled",
	overlappingShiftAllowed: "overlappingShiftSingup.Enabled",
	overlapWindowTime: "overlappingShiftSingup.maxAllowedTime",
	// Note: It is not about consecutive shifts, it is a gap between two shifts. API key based on BE.
	minShiftsGapEnabled: "consecutiveShiftGap.enabled",
	minShiftsGapHours: "consecutiveShiftGap.minHours",
	partialShiftAllowed: "partialShiftSignup.enabled",
	minPartialShiftHours: "partialShiftSignup.minShiftHours",
};

const initialShiftSettings = {
	swapEnabled: false,
	swapApprovalRequired: false,
	cancelEnabled: false,
	forceCancelEnabled: false,
	cancelWindowBeforeStartDays: 0,
	cancelWindowAfterAcceptHrs: 0,
	jobExpiryEnabled: false,
	minWorkHoursRequired: 0,
	minWorkHoursDays: 0,
	forceAssignShift: false,
	forceAssignShiftBeforeStartHours: 0,
	shiftPurpose: false,
	overlappingShiftAllowed: false,
	overlapWindowTime: 30,
	minShiftsGapEnabled: false,
	minShiftsGapHours: 1,
	partialShiftAllowed: false,
	minPartialShiftHours: null,
};

interface WorkCenter {
	id: string | number;
	name: string;
	isOverlapSignupEnabled: boolean;
}

const useShiftSettingsController = (entity: any, locationData: any) => {
	const dispatch = useDispatch();
	const {
		updateCompanySettingsStatus,
		updateLocationDataStatus,
		companySettingsObj,
		locationSettingsObj,
		currentLocationData,
		allSettings,
		updateAllowedWorkCentersForShiftsStatus,
		minShiftDurationHours,
	} = useGatAdminConfigData();

	const [loading, setLoading] = useState(false);

	const [shiftSettings, setShiftSettings] = useState<any>(initialShiftSettings);
	const [newShiftSettings, setNewShiftSettings] = useState<any>({});

	const [shiftSettingsLocationData, setShiftSettingsLocationData] =
		useState<any>(locationData);
	const [newShiftSettingsLocationData, setNewShiftSettingsLocationData] =
		useState<any>({});

	const [overlapWorkcenters, setOverlapWorkcenters] = useState<any>([]);
	const [newOverlapWorkcenters, setNewOverlapWorkcenters] = useState<any>([]);

	const [partialShiftWorkcenters, setPartialShiftWorkcenters] = useState<any>(
		[]
	);
	const [newPartialShiftWorkcenters, setNewPartialShiftWorkcenters] =
		useState<any>([]);

	const settingsObj =
		entity === "location" ? locationSettingsObj : companySettingsObj;

	const { locationWorkcenters } = useMemo(() => {
		if (!currentLocationData?.workCenters) {
			return { locationWorkcenters: [] };
		}
		return {
			locationWorkcenters: currentLocationData.workCenters,
		};
	}, [currentLocationData?.workCenters]);

	useEffect(() => {
		if (locationWorkcenters) {
			const overlapWorkcenters = locationWorkcenters
				?.filter(
					(wc: { isOverlapSignupEnabled: boolean }) => wc.isOverlapSignupEnabled
				)
				.map((wc: { id: number }) => wc.id);

			const partialShiftWorkcenters = locationWorkcenters
				?.filter(
					(wc: { isPartialSignupEnabled: boolean }) => wc.isPartialSignupEnabled
				)
				.map((wc: { id: number }) => wc.id);

			setOverlapWorkcenters(overlapWorkcenters);
			setNewOverlapWorkcenters(overlapWorkcenters);
			setPartialShiftWorkcenters(partialShiftWorkcenters);
			setNewPartialShiftWorkcenters(partialShiftWorkcenters);
		}
	}, [locationWorkcenters, newShiftSettingsLocationData]);

	const handleSave = async () => {
		try {
			setLoading(true);

			const stringifiedNewOverlap = JSON.stringify(newOverlapWorkcenters);
			const stringifiedOverlap = JSON.stringify(overlapWorkcenters);
			const stringifiedNewLocationData = JSON.stringify(
				newShiftSettingsLocationData
			);
			const stringifiedLocationData = JSON.stringify(shiftSettingsLocationData);

			const stringifiedNewPartialShift = JSON.stringify(
				newPartialShiftWorkcenters
			);
			const stringifiedPartialShift = JSON.stringify(partialShiftWorkcenters);

			const changedSettings = findDifferentKeys(
				shiftSettings,
				newShiftSettings
			);
			const changedValues = changedSettings
				.map((key) => {
					const item = shiftObj[key];
					const obj = settingsObj[item] || allSettings[item];
					return obj ? { ...obj, value: newShiftSettings[key] } : null;
				})
				.filter(Boolean);

			const changedRules = findDifferentKeys(
				shiftSettingsLocationData,
				newShiftSettingsLocationData
			);

			if (changedValues.length > 0) {
				await dispatch(updateCompanySettings(changedValues));
			} else if (
				changedRules.length > 0 ||
				stringifiedNewLocationData !== stringifiedLocationData
			) {
				await dispatch(updateLocationData(newShiftSettingsLocationData));
			}

			if (
				stringifiedNewOverlap !== stringifiedOverlap &&
				newShiftSettings.overlappingShiftAllowed
			) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				await dispatch(
					updateAllowedWorkCentersForShifts({
						companyId: currentLocationData?.companyId,
						locationId: currentLocationData?.id,
						workcenterIds: newOverlapWorkcenters,
						SettingTypeCode: "overlappingShiftSingup.Enabled",
					})
				);
			}

			if (
				stringifiedNewPartialShift !== stringifiedPartialShift &&
				newShiftSettings.partialShiftAllowed
			) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				await dispatch(
					updateAllowedWorkCentersForShifts({
						companyId: currentLocationData?.companyId,
						locationId: currentLocationData?.id,
						workcenterIds: newPartialShiftWorkcenters,
						SettingTypeCode: "partialShiftSignup.enabled",
					})
				);
			}
		} catch (error) {
			console.error("Error saving shift settings:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (Object.keys(settingsObj).length > 0) {
			const obj = {
				swapEnabled: settingsObj["shift.swapEnabled"].value,
				swapApprovalRequired:
					settingsObj["shift.swapApprovalRequired"]?.value ?? false,
				cancelEnabled: settingsObj["shift.cancelEnabled"].value,
				forceCancelEnabled:
					settingsObj["shift.forceCancelEnabled"]?.value ?? false,
				cancelWindowBeforeStartDays:
					settingsObj["shift.cancelWindowBeforeStartDays"]?.value ?? 0,
				cancelWindowAfterAcceptHrs:
					settingsObj["shift.cancelWindowAfterAcceptHrs"]?.value ?? 0,
				jobExpiryEnabled: settingsObj["clientApp.jobExpiryEnabled"].value,
				minWorkHoursRequired:
					settingsObj["jobExpiry.minWorkHoursRequired"]?.value ?? 0,
				minWorkHoursDays: settingsObj["jobExpiry.minWorkHoursDays"]?.value ?? 0,
				forceAssignShift:
					settingsObj["assignShiftToWorker.enabled"]?.value ?? false,
				forceAssignShiftBeforeStartHours:
					settingsObj["assignShiftToWorker.hrsBeforeShift"]?.value ?? 0,
				shiftPurpose:
					settingsObj["clientApp.showShiftPurpose.enabled"]?.value ?? false,
				overlappingShiftAllowed:
					settingsObj["overlappingShiftSingup.Enabled"]?.value ?? false,
				overlapWindowTime:
					settingsObj["overlappingShiftSingup.maxAllowedTime"]?.value ?? 30,
				// Note: This is not about consecutive Shifts, it is a gap between two shifts. API key based on BE.
				minShiftsGapEnabled:
					settingsObj["consecutiveShiftGap.enabled"]?.value ?? false,
				minShiftsGapHours:
					settingsObj["consecutiveShiftGap.minHours"]?.value ?? 1,
				partialShiftAllowed:
					settingsObj["partialShiftSignup.enabled"]?.value ?? false,
				minPartialShiftHours:
					settingsObj["partialShiftSignup.minShiftHours"]?.value ??
					minShiftDurationHours,
			};
			setShiftSettings(obj);
			setNewShiftSettings(obj);
		}
	}, [settingsObj, entity, minShiftDurationHours]);

	useEffect(() => {
		if (updateCompanySettingsStatus === "fulfilled") {
			setShiftSettings(newShiftSettings);
			setLoading(false);
		}
	}, [updateCompanySettingsStatus, newShiftSettings]);

	useEffect(() => {
		if (updateLocationDataStatus === "fulfilled") {
			setNewShiftSettingsLocationData(shiftSettingsLocationData);
			setLoading(false);
		}
	}, [updateLocationDataStatus, shiftSettingsLocationData]);

	useEffect(() => {
		if (updateAllowedWorkCentersForShiftsStatus === "fulfilled") {
			setOverlapWorkcenters(newOverlapWorkcenters);
			setPartialShiftWorkcenters(newPartialShiftWorkcenters);
			setLoading(false);
		}
	}, [
		updateAllowedWorkCentersForShiftsStatus,
		newOverlapWorkcenters,
		newPartialShiftWorkcenters,
	]);

	useEffect(() => {
		if (locationData) {
			setShiftSettingsLocationData(locationData);
			setNewShiftSettingsLocationData(locationData);
		}
	}, [locationData]);

	return [
		{
			shiftSettings,
			newShiftSettings,
			updateCompanySettingsStatus,
			shiftSettingsLocationData,
			newShiftSettingsLocationData,
			currentLocationData,
			loading,
			overlapWorkcenters,
			newOverlapWorkcenters,
			partialShiftWorkcenters,
			newPartialShiftWorkcenters,
		},
		{
			setNewShiftSettings,
			setShiftSettingsLocationData,
			setNewShiftSettingsLocationData,
			handleSave,
			setOverlapWorkcenters,
			setNewOverlapWorkcenters,
			setPartialShiftWorkcenters,
			setNewPartialShiftWorkcenters,
		},
	];
};

export default useShiftSettingsController;
