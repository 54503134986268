/* eslint-disable simple-import-sort/imports */
import { Highlight } from "../../../../../../export/helpers";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import useTableHeight from "../../../../../utils/use-table-height";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import {WorkerInfo, WorkerInsightDetailsModel} from "../../../slices/insight-report-types";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {useSelector} from "react-redux";
import Loading from "../../../../../components/Loading";
import { FixedSizeList as _FixedSizeList } from "react-window";
import { FixedSizeListProps } from "react-window";

const FixedSizeList =
	_FixedSizeList as unknown as React.ComponentType<FixedSizeListProps>;

function WorkerInsightDetailsWorkerBackupTab({
	workersReportData,
	workerId,
	allLocations,
}: {
	workersReportData: WorkerInsightDetailsModel;
	workerId: string;
	allLocations: string[];
}) {
	const { height, boxRef } = useTableHeight();
	const [searchTerm, setSearchTerm] = useState("");
	const [rows, setRows] = useState<WorkerInfo[]>([]);

	const fetchSkillsStatus = useSelector(
		(state: any) => state.skills.masterData.status
	);

	useEffect(() => {
		try {
			const newRows: WorkerInfo[] = workersReportData.workerBackupList;
			setRows(newRows);
		} catch (e) {
			// Do nothing
		}
	}, [allLocations, workerId, workersReportData]);

	const filteredData = useMemo(() => {
		return rows ? [...rows]
			.sort((a, b) => a.workerName.localeCompare(b.workerName))
			.filter(
				(item: WorkerInfo) =>
					item.workerName.toLowerCase().includes(searchTerm) ||
					item.primaryJobName?.toLowerCase().includes(searchTerm) ||
					(`${item.locationName} ${item.workcenterName ? " - " + item.workcenterName : ""}`)?.toLowerCase().includes(searchTerm)
			) : [];
	}, [rows, searchTerm]);

	const ListItems: React.FC<{
		item: WorkerInfo;
	}> = ({ item }) => {
		return (
			<Box
				key={`worker-backup-${item.workerId}`}
				sx={{
					display: "flex",
					alignItems: "flex-start",
					justifyContent: "center",
					flexDirection: "column",
					width: "100%",
					border: 1,
					borderLeftWidth: 0,
					borderRightWidth: 0,
					borderTopWidth: 0,
					borderBottomWidth: "1px",
					borderBottomColor: "#F0F0F3",
					height: "87px",
					paddingLeft: "16px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "16px",
						lineHeight: "24px",
						color: "rgba(0, 0, 0, 0.87)",
						fontWeight: 700,
					}}
				>
					<Highlight value={item.workerName} searchText={searchTerm} />
				</Typography>
				{item.primaryJobName && (
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							lineHeight: "20px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						<Highlight value={item.primaryJobName} searchText={searchTerm} />
					</Typography>
				)}
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						lineHeight: "20px",
						color: "rgba(0, 0, 0, 0.60)",
					}}
				>
					<Highlight value={`${item.locationName} ${item.workcenterName ? " - " + item.workcenterName : ""}`} searchText={searchTerm} />
				</Typography>
			</Box>
		);
	};

	if (fetchSkillsStatus === "pending")
		return (
			<Box>
				<Loading />
			</Box>
		);

	return (
		<Box
			ref={boxRef}
			sx={{
				width: "100%",
				paddingTop: "10px",
			}}
		>
			{rows && rows.length > 0 ? (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						padding: "8px",
					}}
				>
					<TextField
						label='Search'
						fullWidth
						placeholder=' '
						variant='filled'
						onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
						size='small'
						value={searchTerm}
						slotProps={{
							input: {
								endAdornment: searchTerm !== undefined &&
									searchTerm.length > 0 && (
										<CancelOutlinedIcon
											sx={{ cursor: "pointer", color: "#92918f" }}
											onClick={() => setSearchTerm("")}
										/>
									),
							},
						}}
						sx={{
							"& .MuiFilledInput-underline:before": {
								borderBottom: "none",
							},
							"& .MuiFilledInput-underline:after": {
								borderBottom: "none",
							},
							//paddingTop: "4px",
							marginBottom: "16px",
							// marginLeft: "24px",
						}}
					/>
					<FixedSizeList
						width='100%'
						height={height - 48}
						itemCount={filteredData.length}
						itemSize={88}
					>
						{({ index, style }) => (
							<Box style={style}>
								<ListItems item={filteredData[index]} />
							</Box>
						)}
					</FixedSizeList>
				</Box>
			) : (
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
						mt: 10,
					}}
				>
					<PersonOffIcon sx={{ color: "#6c6d6e", fontSize: "40px" }} />
					No Coworkers found with matching Skills
				</Box>
			)}
		</Box>
	);
}

export default WorkerInsightDetailsWorkerBackupTab;
