// TODO - Type 'any' needs to be fixed.
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro-6";
import React, { useEffect } from "react";

import SkeletonLoadingOverlay from "../../../../utils/use-skeleton-loading-overlay";
import useViewport from "../../../../utils/use-viewport";
import useSearch from "../../controllers/use-search";

interface ShiftDetailsTableProps {
  rows: any;
  columns: any;
  isShiftPast: boolean;
  setWorkersSelected: (e: any) => void;
  readOnly?: boolean;
  searchValue: string;
  totalWorkersRequired: number;
  statusOptions: any;
  loadingStatus: string;
}

const ShiftDetailsTable: React.FC<ShiftDetailsTableProps> = ({
  rows,
  columns,
  isShiftPast,
  setWorkersSelected,
  readOnly = false,
  searchValue = "",
  totalWorkersRequired,
  statusOptions,
  loadingStatus,
}) => {
  const [selectionModel, setSelectionModel] = React.useState<any>([]);
  const filteredRowsBySearch = useSearch({
    data: rows,
    searchValue,
    searchField: "workerName",
  });
  const filteredRowsByStatus = filteredRowsBySearch.filter((row: any) => {
    if (statusOptions.every((status: any) => !status.checked)) {
      return true;
    }
    return statusOptions.some((status: any) => {
      return status.checked && status.label === row.status;
    });
  });

  useEffect(() => {
    setSelectionModel([]);
  }, [rows]);
  const { height } = useViewport();

  return (
    <Box width={1150} mr="auto" ml="auto" minHeight={200}>
      <DataGridPro
        rows={filteredRowsByStatus}
        columns={columns}
        isRowSelectable={(params) =>
          ["Notified", "Viewed", "Not Notified"].includes(params.row.status)
        }
        checkboxSelection={
          !isShiftPast && !readOnly && totalWorkersRequired > 0
        }
        hideFooter={true}
        loading={loadingStatus === "pending"}
        slots={{
          loadingOverlay: () => (
            <SkeletonLoadingOverlay
              columnData={{
                columnName: "workerName",
                columnStyle: { marginLeft: "12px" },
              }}
            />
          ),
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No workers found
            </Stack>
          ),
        }}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection);
          setWorkersSelected(newSelection);
        }}
        sx={{
          border: 0,
          paddingLeft: "21px",
          paddingRight: "21px",
          marginBottom: "-20px",
          overflowX: "hidden",
          height: height - 493,
          minHeight: 200,
          // "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
          //     display: "none"
          // },
          "& .MuiDataGrid-checkboxInput": {
            margin: "auto", // Center the checkbox vertically
          },
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput": {
            margin: "auto", // Center the header checkbox vertically
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            width: "100%",
          },
          "& .MuiDataGrid-columnSeparator": {
            opacity: "0 !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
            {
              outline: "none !important",
            },
        }}
      />
    </Box>
  );
};

export default ShiftDetailsTable;
