import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/react";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo: string = require("../assets/logo_wilya_dark.svg").default;

interface IErrorBoundaryProps {
    children?: React.ReactNode;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        localStorage.clear();
        Sentry.captureException(error, { extra: errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return <SomethingWentWrong />;
        }

        return this.props.children;
    }
}

const SomethingWentWrong: React.FC = () => {

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            pt={16}
            px={3}
            height="100vh"
            width="100%"
            bgcolor="#f5f5f5"
            textAlign="left"
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box>
                    <img src={logo} alt="Logo" height={75} />
                    <Box px={1.75} mt={4}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Roboto",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    color: "rgba(0,0,0,0.87)",
                                }}
                            >
                                Error Encountered.
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Roboto",
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "rgba(0,0,0,0.57)",
                                    marginLeft: "4px",
                                }}
                            >
                                {"An unexpected issue has occurred."}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "rgba(0,0,0,0.87)",
                                marginTop: "32px",
                            }}
                        >
                            {"We have encountered an issue that prevents the system from operating correctly."}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "rgba(0,0,0,0.57)",
                            }}
                        >
                            {"Please try again later, or contact support if the issue persists."}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ErrorBoundary;