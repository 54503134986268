import jwt_decode from "jwt-decode";
import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { FetchToken, setUser } from "../../export/user";
import { setWorker } from "../../export/web-worker";

interface TokenData {
	exp: number;
	[key: string]: any;
}

interface PrivateRouteProps {
	authenticated: boolean;
	workerApp: boolean;
}

const PrivateRoute = ({ authenticated, workerApp }: PrivateRouteProps) => {
	const dispatch = useDispatch();

	const isTokenValid = (token: string) => {
		const { exp }: TokenData = jwt_decode(token);
		return Date.now() / 1000 < exp;
	};

	const handleWorkerAuth = () => {
		const workerToken = localStorage.getItem("worker-token");
		const workerRefreshToken = localStorage.getItem("worker-refreshToken");

		if (!workerToken || !workerRefreshToken) {
			return <Navigate to='/wl' replace />;
		}

		if (isTokenValid(workerToken)) {
			dispatch(setWorker());
			return <Outlet />;
		}

		return <Outlet />;
	};

	const handleUserAuth = () => {
		const token = localStorage.getItem("token");
		const refreshToken = localStorage.getItem("refreshToken");

		if (!token || !refreshToken) {
			return <Navigate to='/login' replace />;
		}

		if (isTokenValid(token)) {
			const decodedToken: TokenData = jwt_decode(token);
			dispatch(
				setUser({
					userRoles: decodedToken.userRoles,
					id: decodedToken.id,
					showHeatMap: decodedToken.showHeatMap,
					showJobExpiry: decodedToken.showJobExpiry,
					showCrossSkilling: decodedToken.showCrossSkilling,
					shiftCancelReasonEnabled: decodedToken.shiftCancelReasonEnabled,
					skillPriorityEnabled: decodedToken["skillPriority.Enabled"],
					useObsoleteSkills:
						decodedToken["skillPriority.workerScore.useObsoleteSkill"],
					customizedBrandingEnabled: decodedToken.customizedBrandingEnabled,
					certificationsEnabled: decodedToken.certificationsEnabled,
					skillExpiryEnabled: decodedToken.skillExpiryEnabled,
					skillInsightsEnabled: decodedToken.skillInsightsEnabled,
					minDurationHrs: decodedToken.minDurationHrs,
					maxDurationHrs: decodedToken.maxDurationHrs,
					defaultDurationHrs: decodedToken.defaultDurationHrs,
					showWorkerProfile: decodedToken.showWorkerProfile,
					showShiftPurpose: decodedToken.showShiftPurpose,
					fullTimeSchedule: decodedToken.fullTimeSchedule,
					showShiftSwap: decodedToken.showShiftSwap,
					restrictFlexHours: decodedToken.restrictFlexHours,
					schedulerOffsetEnabled: decodedToken.schedulerOffsetEnabled,
					firstName: decodedToken.firstName,
					lastName: decodedToken.lastName,
					username: decodedToken.email,
					company: decodedToken.company,
					companyId: decodedToken.companyId,
					attendMgmt: decodedToken["attendMgmt.enabled"],
					swapAndCancelShift: decodedToken["ftShift.swapAndCancelEnabled"],
					shiftBySkillsMinSkillLevel: decodedToken.shiftBySkillsMinSkillLevel,
					shiftBySkillsEnabled: decodedToken.shiftBySkillsEnabled,
					assignShiftToWorkerEnabled: decodedToken.assignShiftToWorkerEnabled,
					weekStartDay: decodedToken.weekStartDay,
					weekStartHour: decodedToken.weekStartHour,
					jobSkillLevelMapping: decodedToken["jobSkill.skillLevelEnabled"],
					workerSubTypeEnabled: decodedToken["workerSubType.enabled"],
					skillLevelRequirementsEnabled:
						decodedToken.skillLevelRequirementsEnabled,
					allowPartialSignups: decodedToken["partialShiftSignup.enabled"],
				})
			);
			return <Outlet />;
		}

		return <Outlet />;
	};

	if (authenticated) {
		return <Outlet />;
	}

	return workerApp ? handleWorkerAuth() : handleUserAuth();
};

export default PrivateRoute;
