// TODO - Type 'any' needs to be fixed.
import { Alert, Box, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {differenceInMinutes} from "date-fns";
import format from "date-fns/format";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import DialogWrapper from "../../../../../design/dialog-wrapper";
import { resetMarkAttendance } from "../../../store/scheduler-slice";
import ActionButtons from "./action-buttons";
import useMarkAttendanceDialog from "./controllers/use-mark-attendance-dialog";
import MarkAttendanceTable from "./mark-attendance-table";

interface MarkAttendanceDialogProps {
	handleClose: any;
	open: boolean;
	shiftId: number;
}

interface ftShift {
	shiftName: string;
	StartDateTime: string;
	EndDateTime: string;
}

const MarkAttendanceDialog: React.FC<MarkAttendanceDialogProps> = ({
	handleClose,
	open,
	shiftId,
}) => {
	const [
		{
			rows,
			shiftMetadata,
			shiftAttendanceDetailsStatus,
			markAttendanceError,
			markAttendanceStatus,
		},
	] = useMarkAttendanceDialog(shiftId);

	const dispatch = useDispatch();

	const getAvailableTimeRange = useCallback((ftShifts: ftShift[] | null | undefined) => {
		if (!Array.isArray(ftShifts) || ftShifts.length === 0) {
			return {
				availableStartTime: new Date(
					shiftMetadata.startDateTime
				).toISOString(),
				availableEndTime: new Date(shiftMetadata.endDateTime).toISOString(),
			};
		}

		let availableStartTime = new Date(shiftMetadata.startDateTime);
		let availableEndTime = new Date(shiftMetadata.endDateTime);
		const overlappingSignupLimitMins = shiftMetadata.overlappingSignupLimitMins;

		ftShifts.forEach((ftShift) => {
			if (!ftShift?.StartDateTime || !ftShift?.EndDateTime) {
				return;
			}

			const ftStart = new Date(ftShift.StartDateTime);
			const ftEnd = new Date(ftShift.EndDateTime);


			// Adjust available time based on overlaps
			if (ftEnd > availableStartTime && ftEnd < availableEndTime) {
				if (
					differenceInMinutes(ftEnd, availableStartTime) <=
					overlappingSignupLimitMins
				) {
					availableStartTime = ftEnd;
				}
			}
			if (ftStart > availableStartTime && ftStart < availableEndTime) {
				if (
					differenceInMinutes(availableEndTime, ftStart) <=
					Number(overlappingSignupLimitMins)
				) {
					availableEndTime = ftStart;
				}
			}
		});

		return {
			ftAvailableStartTime: availableStartTime.toISOString(),
			ftAvailableEndTime: availableEndTime.toISOString(),
		};
	}, [shiftMetadata]);

	return (
		<>
		<Dialog
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && handleClose) {
					handleClose();
				}
			}}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth='lg'
		>
			<DialogWrapper
				dialogTitle='Mark Attendance'
				dialogSubTitle={`${
					shiftMetadata?.startDate &&
					format(new Date(shiftMetadata?.startDate), "EEE, MMMM do yyyy h:mm a")
				} - ${
					shiftMetadata?.endDate
						? format(new Date(shiftMetadata?.endDate), "h:mm a")
						: null
				}`}
				dialogContent={
					<MarkAttendanceTable
						rows={rows}
						isLoading={shiftAttendanceDetailsStatus === "pending" || markAttendanceStatus === "pending"}
						shiftMetadata={shiftMetadata}
					/>
				}
				actionButtons={
					<ActionButtons
						onClose={handleClose}
						markAttendanceError={markAttendanceError}
						markAttendanceStatus={markAttendanceStatus}
					/>
				}
			/>
		</Dialog>
		<Snackbar
				open={markAttendanceStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetMarkAttendance())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert elevation={6} variant='filled' severity='success'>
					Attendance Marked Successfully
				</Alert>
			</Snackbar>

			<Snackbar
				open={markAttendanceStatus === "error"}
				autoHideDuration={3000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert elevation={6} variant="filled" severity="error" onClose={handleClose}>
					<Box display="flex" flexDirection="column" alignItems="center">
						<Box mb={1}>
							{markAttendanceError ?? "An Error occurred. Please try again."}
						</Box>
					</Box>
				</Alert>
			</Snackbar>
		</>
	);
};

export default MarkAttendanceDialog;
