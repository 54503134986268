// TODO - Type 'any' needs to be fixed.

import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro-6";
import React, { useRef } from "react";

import SkeletonLoadingOverlay from "../../../../../utils/use-skeleton-loading-overlay";
import useMarkAttendanceColumns from "./controllers/use-mark-attendance-columns";

interface MarkAttendanceTableProps {
  rows: any;
  isLoading: boolean | undefined;
  shiftMetadata: any;
}

const MarkAttendanceTable: React.FC<MarkAttendanceTableProps> = ({
  rows,
  isLoading,
  shiftMetadata,
}) => {
  const columns: any = useMarkAttendanceColumns(
    shiftMetadata,
  );
  const dataGridRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      sx={{
        position: "relative",
      }}
      width={700}
      minHeight={158}
    >
      <DataGridPro
        ref={dataGridRef}
        loading={isLoading}
        slots={{
          loadingOverlay: () => <SkeletonLoadingOverlay />,
        }}
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnSelector
        disableDensitySelector
        disableColumnReorder
        disableColumnMenu
        sx={{
          position: "sticky",
          "& .MuiDataGrid-columnHeader": {
            "&.hover-column-header": {
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-sortIcon": {
                display: "none",
              },
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
          },
          ".MuiDataGrid-columnHeader:hover": {
            "& .MuiDataGrid-columnSeparator": {
              display: "flex",
            },
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-root": {
            "& .MuiDataGrid-viewport": {
              overflowX: "hidden !important",
            },
          },
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            minHeight: "158px !important", // Height of two rows and header
          },
          minHeight: "158px", // Height of two rows and header
        }}
      />
    </Box>
  );
};

export default MarkAttendanceTable;
