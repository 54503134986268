import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro-6";
import { AdapterDayjs } from "@mui/x-date-pickers-pro-6/AdapterDayjs";
import format from "date-fns/format";
import isExists from "date-fns/isExists";
import startOfDay from "date-fns/startOfDay";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";

import { ExportButton } from "../../../../../../export/attendance-reports";
import ColumnSelector from "../../../../../../ts/design/column-selector";
import Searchbar from "../../../../../design/search-bar";
import { Filter } from "../../../../filters/filter-accordion/types";
import { setSelectedDayKPI } from "../../../store/worker-activity-slice";
import { ColumnSelector as ColumnSelectorComponent } from "../../../store/worker-activity-slice-types";

interface TableHeaderProps {
	date?: string;
	setDate?: (date: string) => void;
	search?: string;
	setSearch?: (search: string) => void;
	columnSelector?: ColumnSelectorComponent[];
	setColumnSelector?: (columnSelector: ColumnSelectorComponent[]) => void;
	exportData?: () => void;
	open?: boolean;
	setFilterOpen?: (open: boolean) => void;
}

const TableHeader = ({
	date,
	setDate,
	search,
	setSearch,
	columnSelector,
	setColumnSelector,
	exportData,
	open,
	setFilterOpen,
}: TableHeaderProps) => {
	const dispatch = useDispatch();
	const handleSelectColumns = (checked: boolean, field: string) => {
		if (setColumnSelector && columnSelector) {
			setColumnSelector(
				columnSelector.map((column) =>
					column.field === field ? { ...column, checked } : column
				)
			);
		}
	};
	const changeDay = (value: "previous" | "next" | "today") => {
		dispatch(setSelectedDayKPI("ALL"));
		if (!setDate || !date) return;

		const dateMap = {
			previous: () => dayjs(date).subtract(1, "day"),
			next: () => dayjs(date).add(1, "day"),
			today: () => dayjs(),
		};

		setDate(dateMap[value]().toISOString());
	};

	const exportedData = exportData ? exportData() : [];
	return (
		<Box
			display='flex'
			justifyContent='space-between'
			alignItems='center'
			mt={1}
			ml={2}
		>
			<Box display='flex' alignItems='center'>
				<IconButton
					aria-label='worker-column-filter'
					sx={{
						backgroundColor: open ? "#2F4D8B0A" : "none",
						height: "48px",
						width: "48px",
						borderRadius: "5px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
						marginLeft: "8px",
						"&:hover": {
							backgroundColor: "#2F4D8B0A",
						},
					}}
					onClick={() => setFilterOpen?.(!open)}
				>
					<FilterListIcon />
				</IconButton>
				<ColumnSelector
					columns={columnSelector}
					handleSelectColumns={handleSelectColumns}
					disabledColumns={["workerName"]}
				/>
				<Searchbar
					placeholder={" "}
					setSearch={(value) => setSearch?.(value)}
					value={search}
					variant={"filled"}
					width={"60ch"}
				/>

				<Box mx={1} my={2}>
					<Button
						color='primary'
						variant='outlined'
						onClick={() => changeDay("previous")}
					>
						<ArrowBackIcon />
					</Button>
				</Box>
				<Box mx={1} my={2}>
					<Button
						color='primary'
						variant='outlined'
						onClick={() => changeDay("next")}
					>
						<ArrowForwardIcon />
					</Button>
				</Box>
				<Box mx={1} my={2}>
					<Button
						color='primary'
						variant='outlined'
						onClick={() => changeDay("today")}
					>
						Today
					</Button>
				</Box>
				<Box mx={1} my={1}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={dayjs(date)}
							onChange={(newValue) => {
								const date = newValue?.toISOString();
								if (date) {
									setDate?.(date);
								}
							}}
							slotProps={{
								//   field: { clearable: true },
								textField: {
									placeholder: "Select Date",
									variant: "filled",
									size: "small",
									sx: {
										backgroundColor: "#f8f8f8",
										opacity: 0.8,
									},
								},
							}}
							sx={{
								height: "30px",
							}}
							format={"MM/DD/YYYY"}
							label='Select Date'
						/>
					</LocalizationProvider>
				</Box>
			</Box>

			<Box mx={1} my={2}>
				<ExportButton data={exportedData} />
			</Box>
		</Box>
	);
};

export default TableHeader;
