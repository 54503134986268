import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {format, parseISO} from "date-fns";
import React from "react";
import {
    FixedSizeList as _FixedSizeList
} from "react-window";
import { FixedSizeListProps } from "react-window";

import useViewport from "../../../../utils/use-viewport";
import { WorkersExtendedPropV2 } from "../dialog/shift-details-dialog/types";

const FixedSizeList =
    _FixedSizeList as unknown as React.ComponentType<FixedSizeListProps>;

interface ConfirmedWorkersProps {
    shiftMetadata: any;
    confirmedWorkers: WorkersExtendedPropV2[] | undefined;
    loadingStatus: string;
}

const ConfirmedWorkers: React.FC<ConfirmedWorkersProps> = ({
                                                               shiftMetadata,
                                                               confirmedWorkers = [],
                                                               loadingStatus,
                                                           }) => {
    const { height } = useViewport();
    const shiftStartTime = shiftMetadata.startDateTime;
    const shiftEndTime = shiftMetadata.endDateTime;
    const shiftStart = shiftStartTime ? parseISO(shiftStartTime) : new Date();
    const shiftEnd = shiftEndTime ? parseISO(shiftEndTime) : new Date();
    const shiftDuration = (shiftEnd.getTime() - shiftStart.getTime()) / (1000 * 60 * 60);
    const columns = Math.ceil(shiftDuration);
    const hourWidth = columns * 112 > 1102 ? 112 : 1102 / columns;
    const totalWidth = columns * hourWidth;
    const lastColumnWidth = (shiftEnd.getTime() - shiftStart.getTime()) / (1000 * 60 * 60) * hourWidth;

    const renderHeader = () => {
        const headerColumns = [];
        for (let i = 0; i < columns; i++) {
            const currentHour = shiftStart.getTime() + i * 60 * 60 * 1000;
            let displayTime = "";
            if (i === 0) {
                displayTime = format(shiftStart, "h:mm a");
            } else {
                displayTime = format(currentHour, "h:mm a");
            }
            headerColumns.push(
                <Box
                    key={`header-${i}`}
                    width={i === columns - 1 && shiftDuration % 1 !== 0 ? lastColumnWidth : hourWidth}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height="36px"
                    sx={{
                        overflowY: "visible"
                    }}
                >
                    <Box sx={{
                        width: "1px",
                        backgroundColor: "rgba(0,0,0,0.12)",
                        height: height - 472,
                        zIndex: 1,
                    }} />
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}>
                        <Typography
                            style={{
                                lineHeight: "20px",
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(0,0,0,0.87)",
                            }}
                        >
                            {displayTime}
                        </Typography>
                    </Box>
                </Box>
            );
        }

        return (
            <Box
                display="flex"
                width={"100%"}
                sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                }}
            >
                {headerColumns}
            </Box>
        );
    };

    const renderRow = ({ index, style }: any) => {
        const workersInRow = confirmedWorkers.filter((worker) => {
            return confirmedWorkers.indexOf(worker) === index;
        });

        // const workerStart = worker.workerShiftStartDateTime ? parseISO(worker.workerShiftStartDateTime) : shiftStart;
        // const workerEnd = worker.workerShiftEndDateTime ? parseISO(worker.workerShiftEndDateTime) : shiftEnd;
        // const workerStartColumn = Math.max(0, (workerStart.getTime() - shiftStart.getTime()) / (1000 * 60 * 60));
        // const workerEndColumn = Math.min(columns, (workerEnd.getTime() - shiftStart.getTime()) / (1000 * 60 * 60));
        // const workerBoxWidth = (workerEndColumn - workerStartColumn) * hourWidth;
        // const workerBoxLeft = workerStartColumn * hourWidth;

        return (
            <Box display="flex" style={style} alignItems="center">
                <Box
                    width="32px"
                    height="32px"
                    borderRadius="16px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mr="16px"
                    sx={{
                        backgroundColor: "#E0E0E0",
                        position: "sticky",
                        left: 0,
                        zIndex: 3,
                    }}
                >
                    <Typography
                        style={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            fontWeight: 600,
                            color: "rgba(0,0,0,0.87)",
                        }}
                    >
                        {index + 1}
                    </Typography>
                </Box>
                <Box position="relative" width={`${totalWidth}px`} sx={{
                    height: "50px",
                    zIndex: 2,
                }}>
                    {confirmedWorkers.map((worker, workerIndex) => {
                        const workerStart = worker.workerShiftStartDateTime
                            ? parseISO(worker.workerShiftStartDateTime)
                            : shiftStart;
                        const workerEnd = worker.workerShiftEndDateTime
                            ? parseISO(worker.workerShiftEndDateTime)
                            : shiftEnd;
                        const workerStartColumn = Math.max(
                            0,
                            (workerStart.getTime() - shiftStart.getTime()) / (1000 * 60 * 60)
                        );
                        const workerEndColumn = Math.min(
                            columns,
                            (workerEnd.getTime() - shiftStart.getTime()) / (1000 * 60 * 60)
                        );
                        const workerBoxWidth = (workerEndColumn - workerStartColumn) * hourWidth;
                        const workerBoxLeft = workerStartColumn * hourWidth;

                        return (
                        <Box
                            key={workerIndex}
                            position="absolute"
                            left={`${workerBoxLeft}px`}
                            width={`${workerBoxWidth}px`}
                            height="50px"
                            borderRadius="9px"
                            padding="8px"
                            bgcolor="#ECF3F9"
                            border="1px solid #AFAFAF"
                        >
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(0,0,0,0.87)",
                                }}
                            >
                                {worker.workerName}
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(0,0,0,0.6)",
                                }}
                            >
                                {worker.primaryJobName ?? "-"}
                            </Typography>
                        </Box>
                    )})}
                </Box>
            </Box>
        );
    };

    return (
        <Box width={1150} mr="auto" ml="auto" height={height - 456} position="relative">
            <Box sx={{ overflowX: "auto", height: "100%" }}>
                <Box sx={{
                    marginLeft: "48px",
                    marginTop: "16px",
                }}>
                    {renderHeader()}
                </Box>
                <FixedSizeList
                    height={height - 472 - 36}
                    itemCount={confirmedWorkers.length}
                    itemSize={70}
                    width={1150}
                    style={{
                        backgroundColor: "rgba(0,0,0,0)",
                    }}
                >
                    {renderRow}
                </FixedSizeList>
            </Box>
        </Box>
    );
};

export default ConfirmedWorkers;