import { Box, Typography } from "@mui/material";
import React from "react";

import PageHeader from "../../page-header";
import CrossSkillingDashboardTabs from "./cross-skilling-dashboard-tabs";

const ManageCrossSkillingDashboard = ({ fullTimeSchedule }: any) => {

	return (
		<Box >
			{/* BOEING RELATED CHANGES - Full time scheduler text alteration */}
				{/* <PageHeader
					title={fullTimeSchedule ? "Certification" : "Cross skill your workers"}
					subtitle={
						fullTimeSchedule
							? "Track, add and edit your workers' certifications."
							: "Add or edit new skills to your workers, to mark them as interested."
					}
				/> */}
				<Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "24px 24px 0px 24px",
                            borderRadius: "15px 15px 0px 0px",
                        }}
                    >
                        {/* <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "24px",
                                fontWeight: 400,
                                color: "rgba(0,0,0,0.87)",
                            }}
                        >
                            Cross skill your workers
                        </Typography> */}
                    </Box>
			<CrossSkillingDashboardTabs />
		</Box>
	);
};

export default ManageCrossSkillingDashboard;
