// TODO - Type 'any' needs to be fixed.
import { format } from "date-fns";
import { FormikValues } from "formik";
import { Dispatch, SetStateAction, useContext } from "react";

import { setShiftsByFilter } from "../../../../../../../export/shift-scheduler";
import { JOB, SKILLS } from "../../../../../../assets/constants";
import {
	JOB_ROLES,
	SKILLS as SKILLS_FILTER,
} from "../../../../../../components/filters/constants";
import {
	AppliedFilter,
	FilterContext,
} from "../../../../../../components/filters/filter-context";
import useSchedulerData from "../../../../controllers/use-scheduler-data";

type SkillsProps = {
	shiftId?: number;
	skillId: number;
	skillLevelId?: number;
	skillName: string;
	skillLevel?: string;
	level: number;
};

type UseEditShiftDialogForm = {
	state: any;
	skillState: any;
	setToNearestTime: (dateString: string) => string;
	dispatch: (action: any) => void;
	dispatchLocal: (action: any) => void;
	dispatchLocalSkill: (action: any) => void;
	editDraftShift: (arg: any) => void;
	calculateTotalHoursFromStartTime:
		| ((values: any, time: string) => void)
		| undefined;
	calculateTotalHoursFromEndTime:
		| ((values: any, time: string) => void)
		| undefined;
};

type FormValues = {
	setFieldValue: (a: string, b: any) => void;
	values: FormikValues;
};

type Workcenter = {
	location: {
		id: string;
		name: string;
	};
	locationName: string;
	locationId: string;
};

export const useEditShiftDialogForm = ({
	state,
	skillState,
	setToNearestTime,
	dispatch,
	dispatchLocal,
	dispatchLocalSkill,
	editDraftShift,
	calculateTotalHoursFromStartTime,
	calculateTotalHoursFromEndTime,
}: UseEditShiftDialogForm) => {
	const { filterJobRoles, defaultDurationHrs } = useSchedulerData();
	let withinFilterContext = false;
	let setAppliedFilters:
		| Dispatch<SetStateAction<AppliedFilter[]>>
		| ((arg0: { (prevFilters: any): any[]; (prevFilters: any): any[] }) => void)
		| undefined = undefined;
	const filterContext = useContext(FilterContext);
	if (filterContext) {
		withinFilterContext = true;
		setAppliedFilters = filterContext.setAppliedFilters;
	}
	const onFormSubmit = (values: any, actions: any) => {
		const { setSubmitting } = actions;
		const id = values.jobId;
		if (id === "" || id === 0) {
			const skillIds = values.shiftSkills.map(
				(skill: SkillsProps) => skill.skillId
			);
			skillIds.forEach((skillId: number) => {
				if (skillState[skillId] !== true) {
					dispatchLocalSkill({
						type: "toggleCheckbox",
						payload: skillId,
					});
				}
			});
			dispatch(setShiftsByFilter(SKILLS));
		} else {
			if (state[id] !== true) {
				dispatchLocal({
					type: "toggleCheckbox",
					payload: values.jobId,
				});
			}
			dispatch(setShiftsByFilter(JOB));
		}
		dispatch(
			editDraftShift({
				values,
				shiftId: values.shiftId,
				shiftDate: format(new Date(values.shiftDate), "yyyy-MM-dd"),
				shiftPublished: values.shiftPublished,
				currentShift: values.shiftData,
				setSubmitting,
			})
		);
		// Filter related code
		try {
			if (withinFilterContext && setAppliedFilters) {
				const id = values.jobId;
				if (id === "" || id === 0) {
					const newFilter = {
						type: SKILLS_FILTER,
						timeAdded: Date.now(),
						data: values.shiftSkills.map((skill: SkillsProps) => {
							return {
								id: skill.skillId,
								name: skill.skillName,
								timeAdded: Date.now(),
							};
						}),
					};
					setAppliedFilters((prevFilters) => {
						if (prevFilters.length === 0) {
							return [];
						}
						const newFilters = [...prevFilters];
						const filterIndex = newFilters.findIndex(
							(filter) => filter.type === SKILLS_FILTER
						);
						if (filterIndex > -1) {
							const existingData = newFilters[filterIndex].data;
							if (
								!existingData.find(
									(data: { id: any }) => data.id === newFilter.data[0].id
								)
							) {
								newFilters[filterIndex].data.push(newFilter.data[0]);
							}
						} else {
							newFilters.push(newFilter);
						}
						return newFilters;
					});
				} else {
					const newFilter = {
						type: JOB_ROLES,
						timeAdded: Date.now(),
						data: filterJobRoles
							.filter((job: any) => job.id === id)
							.map((job: any) => {
								return {
									id: job.id,
									name: job.name,
									timeAdded: Date.now(),
								};
							}),
					};
					setAppliedFilters((prevFilters) => {
						if (prevFilters.length === 0) {
							return [];
						}
						const newFilters = [...prevFilters];
						const filterIndex = newFilters.findIndex(
							(filter) => filter.type === JOB_ROLES
						);
						if (filterIndex > -1) {
							const existingData = newFilters[filterIndex].data;
							if (
								!existingData.find(
									(data: { id: any }) => data.id === newFilter.data[0].id
								)
							) {
								newFilters[filterIndex].data.push(newFilter.data[0]);
							}
						} else {
							newFilters.push(newFilter);
						}
						return newFilters;
					});
				}
			}
		} catch (e) {
			// Do nothing
		}
	};

	const onStartTimeChange = (dateString: string, formValues: FormValues) => {
		const { setFieldValue, values } = formValues;
		setFieldValue("startTime", dateString);
		const endTime = new Date(dateString);
		endTime.setHours(endTime.getHours() + defaultDurationHrs);
		setFieldValue("endTime", endTime.toISOString());
		calculateTotalHoursFromStartTime &&
			calculateTotalHoursFromStartTime(values, dateString);
	};

	const onEndTimeChange = (dateString: string, formValues: FormValues) => {
		const { setFieldValue, values } = formValues;
		setFieldValue("endTime", setToNearestTime(dateString));
		calculateTotalHoursFromEndTime &&
			calculateTotalHoursFromEndTime(values, dateString);
	};

	const getLocationWorkcenters = (workcenters: Workcenter[]): Workcenter[] => {
		const locObj: Record<string, Workcenter[]> = {};
		workcenters.forEach((wc) => {
			if (locObj[wc.location.id]) {
				locObj[wc.location.id].push({
					...wc,
					locationName: wc.location.name,
					locationId: wc.location.id,
				});
			} else {
				locObj[wc.location.id] = [
					{ ...wc, locationName: wc.location.name, locationId: wc.location.id },
				];
			}
		});
		const result: Workcenter[] = [];
		Object.values(locObj).forEach((loc: any) =>
			loc.forEach((wc: any) => result.push(wc))
		);
		return result;
	};

	return [
		{},
		{
			onFormSubmit,
			onEndTimeChange,
			onStartTimeChange,
			getLocationWorkcenters,
		},
	];
};
