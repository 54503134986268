import CloseIcon from "@mui/icons-material/Close";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Snackbar,
	Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { useDispatch } from "react-redux";

import { resetUpdateAllowedWorkCentersForShifts } from "../../../../store/gat-admin-slice";

interface AllowedWorkcentersProps {
	loading: boolean;
	selectedWorkcenters: string[];
	handleWorkcenterChange: (event: SelectChangeEvent<string[]>) => void;
	handleDelete: (event: React.MouseEvent, workcenterToDelete: string) => void;
	locationWorkcenters: any[];
	wc: { [key: number]: string };
	updateStatus: string;
}

const AllowedWorkcenters = ({
	loading,
	selectedWorkcenters,
	handleWorkcenterChange,
	handleDelete,
	locationWorkcenters,
	wc,
	updateStatus,
}: AllowedWorkcentersProps) => {
	const dispatch = useDispatch();

	return (
		<Box
			sx={{
				marginTop: "8px",
				marginBottom: "8px",
				marginLeft: "8px",
				width: "100%",
			}}
		>
			<Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "24px",
						letterSpacing: "0.15px",
					}}
				>
					Applicable Workcenters
				</Typography>
			</Box>
			<Box sx={{ display: "flex", gap: 2 }}>
				<FormControl sx={{ width: "100%", marginTop: 2 }}>
					<InputLabel id='workcenter-select-label'>
						Select Workcenters
					</InputLabel>
					<Select
						input={<OutlinedInput label='Select Workcenters' />}
						multiple
						value={selectedWorkcenters}
						onChange={handleWorkcenterChange}
						disabled={loading}
						renderValue={(selected) => {
							if (selected.length === 0) {
								return "Select Workcenters";
							}
							if (
								selected.length === locationWorkcenters?.length &&
								locationWorkcenters?.length > 5
							) {
								return "All Workcenters Selected";
							}
							return (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selected.map((value) => (
										<Chip
											key={value}
											label={wc[Number(value)]}
											deleteIcon={
												<Box
													onMouseDown={(event) => event.stopPropagation()}
													sx={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														width: "20px",
														height: "20px",
														backgroundColor: "#aeaeae",
														borderRadius: "50%",
														cursor: "pointer",
													}}
												>
													<CloseIcon
														sx={{
															color: "#ebebeb",
															fontSize: "16px",
														}}
													/>
												</Box>
											}
											onDelete={(event) => handleDelete(event, value)}
										/>
									))}
								</Box>
							);
						}}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 300,
								},
							},
						}}
					>
						<MenuItem value='all'>
							<Checkbox
								checked={
									selectedWorkcenters.length === locationWorkcenters?.length
								}
								indeterminate={
									selectedWorkcenters.length > 0 &&
									selectedWorkcenters.length < locationWorkcenters?.length
								}
							/>
							<ListItemText primary='Select All' />
						</MenuItem>
						{locationWorkcenters?.map(
							(workcenter: { id: string; name: string }) => (
								<MenuItem key={workcenter.id} value={workcenter.id}>
									<Checkbox
										checked={selectedWorkcenters.includes(workcenter.id)}
									/>
									<ListItemText primary={workcenter.name} />
								</MenuItem>
							)
						)}
					</Select>
				</FormControl>
			</Box>
			{updateStatus === "fulfilled" && (
				<Snackbar
					open={updateStatus === "fulfilled"}
					autoHideDuration={4000}
					onClose={() => dispatch(resetUpdateAllowedWorkCentersForShifts())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Workcenters updated successfully
					</MuiAlert>
				</Snackbar>
			)}
		</Box>
	);
};

export default AllowedWorkcenters;
