import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Collapse, Divider, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-7";
import React, { SetStateAction } from "react";

import SortedDataGrid7 from "../../../../../design/sorted-datagrid-7";
import useTableHeight from "../../../../../utils/use-table-height";
import AppliedFilters from "../../../../filters/applied-filters";
import FilterAccordion from "../../../../filters/filter-accordion";
import { Filter } from "../../../../filters/filter-accordion/types";
import useMonthlyActivityColumn from "../../../controllers/use-monthly-activity-column";
import { useWorkerActivityData } from "../../../controllers/use-worker-activity-data";
import {
	ColumnSelector,
	WorkerActivityData,
	WorkerActivityFilters,
} from "../../../store/worker-activity-slice-types";
import KpiSummaries from "../kpi-summaries";

const NoReportsAvailable = () => {
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<CalendarTodayIcon fontSize='large' />
				<Typography>No Workers found</Typography>
			</Box>
		</div>
	);
};
const MonthlyActivityTable = ({
	data,
	search,
	columnSelector,
	isFilterOpen = false,
	allFilters,
	setAllFilters,
	selectedKPI,
	setSelectedKPI,
	KPICount,
}: {
	data?: WorkerActivityData[];
	search?: string;
	columnSelector?: ColumnSelector[];
	isFilterOpen?: boolean;
	allFilters?: Filter[];
	setAllFilters?: (allFilters: Filter[]) => void;
	selectedKPI?: string;
	setSelectedKPI?: (selectedKPI: string) => void;
	KPICount: any;
}) => {
	const { boxRef, height } = useTableHeight();
	const { monthlyActivityReportStatus } = useWorkerActivityData();
	const columns = useMonthlyActivityColumn(search || "");
	const containerHeight = height - 127; // Subtract header height

	const filteredColumns = columns.filter((column) =>
		columnSelector?.some((c) => column.field === c.field && c.checked)
	);

	// remove duplicate rows by id
	const uniqueData = data?.filter(
		(item, index, self) => index === self.findIndex((t) => t.id === item.id)
	);

	return (
		<Box ref={boxRef} sx={{ flexGrow: 1 }}>
			<Grid
				container
				wrap='nowrap'
				spacing={0}
				sx={{ position: "relative", flex: 1 }}
			>
				<Collapse
					orientation='horizontal'
					in={isFilterOpen}
					sx={{
						position: "absolute",
						left: 0,
						top: 0,
						zIndex: 2,
						backgroundColor: "white",
						borderRight: "1px solid rgba(0, 0, 0, 0.12)",
						height: "100%",
						width: "282px",
					}}
				>
					<Grid item sx={{ width: "282px" }}>
						<Box
							sx={{
								height: "100%",
								overflowY: "auto",
								overflowX: "hidden",
								width: "282px",
								/* Hide scrollbar for Chrome, Safari and Opera */
								"&::-webkit-scrollbar": {
									display: "none",
								},
								/* Hide scrollbar for IE, Edge and Firefox */
								msOverflowStyle: "none",
								scrollbarWidth: "none",
							}}
						>
							<FilterAccordion
								filters={allFilters ?? []}
								setFilters={(value: SetStateAction<Filter[]>) => {
									if (setAllFilters) {
										setAllFilters(
											typeof value === "function" ? value([]) : value
										);
									} else {
										console.log("setAllFilters");
									}
								}}
								defaultAppliedFilters={[]}
								minFilterAccordionHeight={100}
							/>
						</Box>
					</Grid>
				</Collapse>

				<Grid
					item
					xs
					lg
					md
					sm
					sx={{
						pl: isFilterOpen ? "282px" : 0,
						transition: "padding-left 0.3s ease",
					}}
				>
					<Box>
						<Divider sx={{ mt: 0 }} />
						<AppliedFilters />
						<Box ml={2.5}>
							<KpiSummaries
								selectedKPI={selectedKPI ?? ""}
								setSelectedKPI={
									setSelectedKPI ??
									(() => {
										console.log("setSelectedKPI");
									})
								}
								KPICount={KPICount}
							/>
						</Box>
					</Box>
					<SortedDataGrid7
						rows={uniqueData ? uniqueData : []}
						columns={filteredColumns}
						pagination
						disableColumnSelector
						disableDensitySelector
						disableColumnMenu
						disableRowSelectionOnClick
						disableColumnReorder
						unstable_rowSpanning={true}
						defaultSortField='workerName'
						loading={monthlyActivityReportStatus === "pending"}
						slots={{
							noRowsOverlay: NoReportsAvailable,
						}}
						initialState={{
							pagination: { paginationModel: { pageSize: 25 } },
						}}
						pageSizeOptions={[25, 50, 100]}
						sx={{
							height: containerHeight,
							flex: 1,
							mt: 1,
							border: "none !important",
							"& .MuiDataGrid-columnHeader": {
								"&.hover-column-header": {
									"& .MuiDataGrid-columnSeparator": {
										display: "none",
									},
									"& .MuiDataGrid-sortIcon": {
										display: "none",
									},
								},
								"& .MuiDataGrid-columnSeparator": {
									display: "none",
								},
							},
							".MuiDataGrid-cell:focus": {
								outline: "none",
							},
							// "& .MuiDataGrid-row": {
							// 	cursor: "pointer",
							// },
							"& .MuiTablePagination-root": {
								marginRight: "60px",
							},
							".MuiDataGrid-cell": {
								"&:focus": {
									outline: "none",
								},
								"&.hover-column-cell": {
									position: "sticky",
									right: 0,
									zIndex: 1,
								},
								"& .hover-content": {
									transition: "opacity 0.3s ease-in-out",
									opacity: 0,
								},
							},
							".MuiDataGrid-columnHeader:hover": {
								"& .MuiDataGrid-columnSeparator": {
									display: "flex",
								},
							},
							".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
								opacity: 1,
								backgroundColor: "#f5f5f5",
							},
							"& .MuiDataGrid-row.Mui-selected .hover-column-cell .hover-content":
								{
									backgroundColor: "#e7e9f0",
								},
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default MonthlyActivityTable;
