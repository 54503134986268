/* eslint-disable no-mixed-spaces-and-tabs */
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";

import Button from "../../../../../design/button";
import useSchedulerData from "../../../controllers/use-scheduler-data";

type ActionButtonsProps = {
  handleClose: (() => void) | undefined;
  isValid: boolean;
  dirty: boolean;
  isSubmitting: boolean;
  isShiftSwap?: boolean;
  isDisabled?: boolean;
  availableWorkers: number;
  jobSelected: boolean;
  skillSelected: boolean;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
  handleClose,
  isValid,
  dirty,
  isSubmitting,
  isShiftSwap,
  isDisabled,
  availableWorkers,
  jobSelected,
  skillSelected,
}) => {
  const { createDraftShiftAsyncErrorMessage, userRoles } = useSchedulerData();

  return (
    <Box
      display="flex"
      mt={isShiftSwap ? 2 : 4}
      width="100%"
      alignItems="center"
      flexDirection="column"
    >
      <Box sx={{
        width: "100%",
      }}>
        {isShiftSwap &&
        availableWorkers === 0 &&
        (jobSelected || skillSelected) ? (
            <Box
                sx={{
                  display: "flex",
                  padding: "12px",
                  flexDirection: "row",
                  backgroundColor: "#FBEAEA",
                  borderRadius: "4px",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
            >
              <ErrorOutlineOutlinedIcon sx={{ color: "#E44B48" }} />
              <Box sx={{ marginLeft: "12px", marginTop: "2px" }}>
                <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#541313",
                    }}
                >
                  No eligible workers available to approve this shift.
                </Typography>
              </Box>
            </Box>
        ) : null}
      </Box>
      <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
      >
        <Box
            display="flex"
        >
          <Button color="primary" variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
              color="primary"
              type="submit"
              customStyles={{
                marginLeft: "16px",
              }}
              disabled={
                (Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false)
                    ? true
                    : !isValid ||
                    !dirty ||
                    isSubmitting ||
                    isDisabled ||
                    (isShiftSwap &&
                        availableWorkers === 0 &&
                        (jobSelected || skillSelected))
              }
          >
            {isSubmitting ? (
                <div style={{ position: "relative" }}>
                  <CircularProgress
                      size={25}
                      style={{ position: "absolute", left: "50%" }}
                  />{" "}
                  {isShiftSwap ? "Approving" : "Creating Shift"}
                </div>
            ) : isShiftSwap ? (
                "Approve"
            ) : (
                "Create Shift"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ActionButtons;
