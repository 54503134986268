import { Box } from "@mui/material";
import debounce from "lodash.debounce";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// import { CrossSkillLegends } from "../../../../../export/cross-skilling";
import ColumnSelector from "../../../../design/column-selector";
import Searchbar from "../../../../design/search-bar";
import useViewport from "../../../../utils/use-viewport";
import { Column, SkillLevel } from "../../types";
import CrossSkillFilterButton from "./ui/cross-skill-filter-button";
import CrossSkillLegends from "./ui/cross-skill-ledgends";

interface CrossSkillHeaderProps {
  allSkillLevels: SkillLevel[];
  allLocations: { id: string; name: string }[];
  selectedLocation: string;
  filterStatus: boolean;
  setFilterStatus: (status: boolean) => void;
  columns: Column[];
  handleSelectColumns: (checked: boolean, field: string) => void;
  searchText: string;
  search: (text: string) => void;
  skillMatrixFiltersStatus: string;
  isSkillPriorityEnabled: boolean;
  notAssignedSkillLevel?: SkillLevel;
}

const CrossSkillHeader: React.FC<CrossSkillHeaderProps> = ({
  allSkillLevels,
  filterStatus,
  setFilterStatus,
  columns,
  handleSelectColumns,
  searchText,
  search,
  isSkillPriorityEnabled,
  notAssignedSkillLevel = undefined,
}) => {
  const { width } = useViewport();
  const placeHolder = ""
  // columns
  //   ? `${columns
  //       .filter((column) => column.field !== "skillScore")
  //       .map((column) => column.headerName)
  //       .join(", ")}, Jobs and Skills`
  //   : "Search"
    ;

  const [ledgendsInitialized, setLegendsInitialized] = useState<boolean>(false);
  const [legendsWidth, setLegendsWidth] = useState(0);
  const [ledgendsTakingMoreSpace, setLedgendsTakingMoreSpace] =
    useState<boolean>(false);
  const legendsRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const headerBoxRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLegendsInitialized(false);
  }, []);

  useEffect(() => {
    if (legendsRef.current) {
      if (legendsRef.current.offsetWidth === 0) return;
      if (!ledgendsInitialized) {
        setLegendsWidth(legendsRef.current.offsetWidth);
        setLegendsInitialized(true);
      }
    }
  }, [width, allSkillLevels, ledgendsInitialized]);

  const handleResize = useCallback(() => {
    const headerBoxRefElement = headerBoxRef.current;
    if (!ledgendsInitialized || !headerBoxRefElement) return;

    const headerBoxWidth = headerBoxRefElement.offsetWidth;
    setLedgendsTakingMoreSpace(legendsWidth > headerBoxWidth / 2);
  }, [ledgendsInitialized, legendsWidth]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleResize = useCallback(debounce(handleResize, 100), [
    handleResize,
    width,
  ]);

  useEffect(() => {
    const legendsRefElement = legendsRef.current;
    const headerBoxRefElement = headerBoxRef.current;
    if (!legendsRefElement || !headerBoxRefElement) return;

    // Create a ResizeObserver to observe changes to the box size
    const resizeObserver = new ResizeObserver(debouncedHandleResize);

    // Start observing the box element and the parent element
    resizeObserver.observe(legendsRefElement);
    resizeObserver.observe(headerBoxRefElement);

    // Handle initial size calculation
    debouncedHandleResize();

    // Cleanup observer on component unmount
    return () => {
      resizeObserver.unobserve(legendsRefElement);
      resizeObserver.unobserve(headerBoxRefElement);
      debouncedHandleResize.cancel();
    };
  }, [debouncedHandleResize]);

  const disabledColumns = useMemo(() => {
    const isNameChecked = columns?.find((col) => col?.field === "name")?.checked;
    const isWorkerIdChecked = columns?.find(
      (col) => col?.field === "companyEmployeeId"
    )?.checked;
    if (
      (isNameChecked && !isWorkerIdChecked) ||
      (!isNameChecked && isWorkerIdChecked)
    ) {
      return isNameChecked ? ["name"] : ["companyEmployeeId"];
    }
    return [];
  }, [columns]);
  

  return (
    <Box
      ref={headerBoxRef}
      display="flex"
      flexDirection="column"
      mx={2}
      my={1}
      width={"100%"}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        my={1}
        mr={2}
        sx={{
          flex: 1,
        }}
      >
        <CrossSkillFilterButton
          openFilter={(value) => setFilterStatus(value)}
          filterStatus={filterStatus}
        />
        <ColumnSelector
          columns={
            isSkillPriorityEnabled
              ? columns
              : columns
              ? columns.filter((column) => column.field !== "skillScore")
              : []
          }
          handleSelectColumns={handleSelectColumns}
          disabledColumns={disabledColumns}
        />
        <Searchbar
          placeholder={placeHolder}
          setSearch={(value) => search(value)}
          value={searchText}
          debounce
        />
        <Box
          ref={legendsRef}
          display="flex"
          alignItems="center"
          mx={1}
          sx={{
            marginLeft: "24px",
          }}
        >
          <CrossSkillLegends
            allSkillLevels={allSkillLevels}
            notAssignedSkillLevel={notAssignedSkillLevel}
            isWide={ledgendsTakingMoreSpace}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CrossSkillHeader;
