import {
	Box,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Switch,
	Typography,
} from "@mui/material";
import React, { useMemo } from "react";

import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import AllowedWorkcenters from "./allowed-workcenters";

interface WorkCenter {
	id: string | number;
	name: string;
	isOverlapSignupEnabled: boolean;
}

interface OverlapShiftsProps {
	newShiftSettings: {
		overlappingShiftAllowed: boolean;
		overlapWindowTime: number;
	};
	setNewShiftSettings: React.Dispatch<React.SetStateAction<any>>;
	loading: boolean;
	overlapWorkcenters: string[];
	newOverlapWorkcenters: string[];
	setNewOverlapWorkcenters: React.Dispatch<React.SetStateAction<string[]>>;
}

const OverlapShifts: React.FC<OverlapShiftsProps> = ({
	newShiftSettings,
	setNewShiftSettings,
	overlapWorkcenters,
	newOverlapWorkcenters,
	setNewOverlapWorkcenters,
	loading,
}) => {
	const {
		getLocationSettingsStatus,
		currentLocationData,
		locationSettingsObj,
		updateAllowedWorkCentersForShiftsStatus,
	} = useGatAdminConfigData();

	const { wc, locationWorkcenters } = useMemo(() => {
		if (!currentLocationData?.workCenters) {
			return { wc: {}, locationWorkcenters: [] };
		}

		const wc = Object.fromEntries(
			currentLocationData.workCenters.map((wc: WorkCenter) => [wc.id, wc.name])
		);

		return {
			wc,
			locationWorkcenters: currentLocationData.workCenters,
		};
	}, [currentLocationData?.workCenters]);

	const handleWorkcenterChange = (
		event: SelectChangeEvent<typeof overlapWorkcenters>
	) => {
		const value = event.target.value;
		if (value.includes("all")) {
			if (newOverlapWorkcenters.length === locationWorkcenters?.length) {
				setNewOverlapWorkcenters([]);
			} else {
				setNewOverlapWorkcenters(
					locationWorkcenters?.map((wc: { id: number }) => wc.id) || []
				);
			}
		} else {
			setNewOverlapWorkcenters(value as string[]);
		}
	};

	const handleDelete = (
		event: React.MouseEvent,
		workcenterToDelete: string
	) => {
		event.stopPropagation();
		if (!newShiftSettings.overlappingShiftAllowed) {
			return;
		}
		setNewOverlapWorkcenters(
			newOverlapWorkcenters.filter((wc) => wc !== workcenterToDelete)
		);
	};

	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Allow Full-Time Workers to pick up Overtime Shifts that overlap with
						their Full-Time Shift
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Full Time Workers can be notified of Overtime Shifts that overlap
						with their Full Time Shift to extend their regular work
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{loading || getLocationSettingsStatus === "pending" ? (
						<Skeleton width={50} height={40} />
					) : (
						<Switch
							checked={newShiftSettings.overlappingShiftAllowed ?? false}
							onChange={(e) => {
								setNewShiftSettings((prevSettings: any) => ({
									...prevSettings,
									overlappingShiftAllowed: e.target.checked,
									overlapWindowTime: e.target.checked
										? 30
										: prevSettings.overlapWindowTime,
								}));
							}}
						/>
					)}
				</Box>
			</Box>

			{newShiftSettings.overlappingShiftAllowed ? (
				<Box
					sx={{
						marginTop: "32px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "16px",
									color: "rgba(0, 0, 0, 0.87)",
									marginLeft: "8px",
								}}
							>
								Allowed overlap with the Full-Time Shift
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginLeft: "8px",
							}}
						>
							{getLocationSettingsStatus === "pending" ? (
								<Skeleton width={60} height={50} />
							) : (
								<Select
									id='days-to-accept-shift'
									variant='filled'
									sx={{
										"& .MuiInputBase-input": {
											background: "#FFF",
											padding: "8px",
										},
										width:
											newShiftSettings.overlapWindowTime > 90 ? "70px" : "60px",
									}}
									onChange={(e) => {
										setNewShiftSettings((prevSettings: any) => ({
											...prevSettings,
											overlapWindowTime: Number(e.target.value),
										}));
									}}
									value={newShiftSettings.overlapWindowTime ?? 30}
								>
									<MenuItem value={30}>30</MenuItem>
									<MenuItem value={60}>60</MenuItem>
									<MenuItem value={90}>90</MenuItem>
									<MenuItem value={120}>120</MenuItem>
								</Select>
							)}
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "16px",
									color: "rgba(0, 0, 0, 0.87)",
									marginLeft: "8px",
								}}
							>
								minutes
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<AllowedWorkcenters
							loading={
								loading ||
								updateAllowedWorkCentersForShiftsStatus === "pending" ||
								getLocationSettingsStatus === "pending"
							}
							selectedWorkcenters={newOverlapWorkcenters}
							handleWorkcenterChange={handleWorkcenterChange}
							handleDelete={handleDelete}
							locationWorkcenters={locationWorkcenters}
							wc={wc}
							updateStatus={updateAllowedWorkCentersForShiftsStatus}
						/>
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default OverlapShifts;
