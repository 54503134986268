/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable simple-import-sort/imports */
import { Box } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import ExportButton from "../../../../design/export-button";
import ColumnSelector from "../../../../design/column-selector";
import Searchbar from "../../../../design/search-bar";
import useViewport from "../../../../utils/use-viewport";
import InsightReportLocationFilter from "../../../insight-dashboard/ui/insight-report-location-filter";
import CrossSkillingDashboardSummaries from "./cross-skilling-dashboard-summaries";
import {
	InterestedWorkersCount,
	Location,
	SkillInsightModel,
	SkillLevel,
	WorkerInsightModel, columnType,
} from "../../../../components/insight-dashboard/slices/insight-report-types";

interface CrossSkillingDashboardHeaderProps {
	isSkillPriorityEnabled: boolean;
	jobSkillLevelMapping: boolean;
	allPriorities: any[];
	allLocations: Location[];
	allSkillLevels: SkillLevel[];
	selectedLocations: number[];
	setSelectedLocations: React.Dispatch<React.SetStateAction<number[]>>;
	setSearchText: React.Dispatch<React.SetStateAction<string>>;
	searchText: string;
	overAllColumns: columnType[];
	setOverAllColumns: React.Dispatch<React.SetStateAction<columnType[]>>;
	fetchWorkerInsightReportStatus: "idle" | "pending" | "fulfilled" | "error";
	exportData: any;
	fetchSkillInsightReportStatus: "idle" | "pending" | "fulfilled" | "error";
	noOfWorkers: number;
	noOfSkills: number;
	selectedKPI: string;
	setKpi: React.Dispatch<React.SetStateAction<string>>;
	skillsWithLessThanXWorkers: SkillInsightModel[];
	workersWithLessThenXSkills: WorkerInsightModel[];
	kpiCount: any;
}

const CrossSkillingDashboardDataHeader: React.FC<
	CrossSkillingDashboardHeaderProps
> = ({
	isSkillPriorityEnabled,
	jobSkillLevelMapping,
	allPriorities,
	allLocations,
	allSkillLevels,
	selectedLocations,
	setSelectedLocations,
	setSearchText,
	searchText,
	overAllColumns,
	setOverAllColumns,
	fetchWorkerInsightReportStatus,
	exportData,
	fetchSkillInsightReportStatus,
	selectedKPI,
	setKpi,
	skillsWithLessThanXWorkers,
	workersWithLessThenXSkills,
	noOfWorkers,
	noOfSkills,
	kpiCount,
}) => {
	const { width }: any = useViewport();

	const locationColumnEnabled = overAllColumns.find(
		(item: columnType) =>
			item.field === "workerLocation" && item.checked === true
	);
	const workcenterColumnEnabled = overAllColumns.find(
		(item: columnType) => item.field === "workcenterName" && item.checked === true
	);

	const primaryJobColumnEnabled = overAllColumns.find(
		(item: columnType) => item.field === "primaryJobName" && item.checked === true
	);

	const islocationColumnEnabled = !!locationColumnEnabled;
	const isworkcenterColumnEnabled = !!workcenterColumnEnabled;
	const isprimaryJobColumnEnabled = !!primaryJobColumnEnabled;

	const exportFilteredData = () => {
		if (
			selectedKPI !== "skillsWithLessThenXWorkersKPI" &&
			selectedKPI !== "interestedWorkersKPI"
		) {
			return exportWorkerInsight();
		} else {
			return exportSkillInsights();
		}
	};

	const exportWorkerInsight = () => {
		const template = exportData.map((item: any) => {
			const workerSkillsAtEachLevel: any = {};

			let totalSkillsCount = 0;
			if (!isSkillPriorityEnabled) {
				for (let i = 0; i < allSkillLevels.length; i++) {
					const skill = item.workerSkillCountByLevel?.find(
						(x: any) => x.level === allSkillLevels[i].level
					);
					workerSkillsAtEachLevel[` ${allSkillLevels[i].name} `] = skill
						? skill.count
						: "";
				}
			}
			item.workerSkillCountByLevel?.forEach((skill: any) => {
				const level = allSkillLevels.find((x) => x.level === skill.level);
				if (level && level.isQualifiedAsSkilled) {
					if (jobSkillLevelMapping) {
						const minRequiredLevel = allSkillLevels.find(
							(x: { id: number }) => x.id === skill.minSkillLevelId
						);
						if (minRequiredLevel && minRequiredLevel.level <= skill.level) {
							totalSkillsCount += skill.count;
						}
					} else {
						totalSkillsCount += skill.count;
					}
				}
			});

			const prs =
				isSkillPriorityEnabled &&
				allPriorities.reduce((acc, val) => {
					acc[val.name] = item.workerSkillCountByPriority.find(
						(x: any) => x.priorityId === val.id
					)
						? item.workerSkillCountByPriority.find(
								(x: any) => x.priorityId === val.id
						  ).count
						: 0;
					return acc;
				}, {});

			const totalSkillScore = isSkillPriorityEnabled && {
				TotalSkillScore: item.skillScore,
			};

			const requiredSkills = jobSkillLevelMapping && {
				RequiredSkills: item.requiredSkillsCount,
			};

			const  skillDemonstrated = jobSkillLevelMapping && {
				SkillDemonstrated: item.workerSkillsDemostratedCount,
			};

			const skillsGap = jobSkillLevelMapping && {
				SkillsGap: item.workerSkillsGapCount,
			};

			const otherSkills = jobSkillLevelMapping && {
				OtherSkills: item.workerOtherSkillsCount,
			};

			const totalSkills = !jobSkillLevelMapping && {
				TotalSkills: totalSkillsCount,
			};

			return {
				WorkerName: item.firstName + " " + item.lastName,
				WorkerLocation: item.locationName,
				Workcenter: item.workcenterName,
				PrimaryJob: item.primaryJobName,
				...prs,
				JoiningDate: format(new Date(item.joiningDate), "PPP"),
				...workerSkillsAtEachLevel,
				...requiredSkills,
				...totalSkills,
				...skillDemonstrated,
				...skillsGap,
				...otherSkills,
				...totalSkillScore,
				InterestedSkills: item.interestedSkillsCount,
				WorkerBackup: item.workerBackupCount,
			};
		});
		return template;
	};

	//Export data in csv format
	const exportSkillInsights = () => {
		const template = exportData.map((item: any) => {
			const workersAtEachLevel: any = {};

			let workerCoverageCount = 0;

			const workerCountObjects = item.workersSkillLevel
				.filter((x: any) => selectedLocations.includes(x.locationId)) // Filter by selected locations
				.flatMap((x: any) => x.workerCount);

			if (selectedKPI !== "interestedWorkers") {
				allSkillLevels.forEach((skillLevel) => {
					const skillByLevel = workerCountObjects.find(
						(x: any) => x.level === skillLevel.level
					);
					if (skillByLevel) {
						workersAtEachLevel[` ${skillLevel.name} `] =
							skillByLevel.count;
					} else {
						workersAtEachLevel[` ${skillLevel.name} `] = "";
					}
				});
			}

			item.workerSkillCountByLevel?.forEach((skill: any) => {
				const lev = allSkillLevels.find((x: any) => x.level === skill.level);
				if (lev && lev.isQualifiedAsSkilled) {
					workerCoverageCount += skill.count;
				}
			});
			const selectedSkillPriority = isSkillPriorityEnabled && allPriorities.find(value =>
				value.id === item?.priorityId);
			const skillPriority = isSkillPriorityEnabled && {
				SkillPriority: selectedSkillPriority ?
					`${selectedSkillPriority?.code} (${selectedSkillPriority?.name})` : "",
			};

			const getInterestedWorkersCount = (item: { interestedWorkersCount: any }) => {
				try {
					let count = 0;
					const interestedWorkersCount = item.interestedWorkersCount;
					interestedWorkersCount.forEach(
						(interestedWorkerCount: InterestedWorkersCount) => {
							if (selectedLocations && selectedLocations.length) {
								// If filter is applied
								if (selectedLocations.includes(interestedWorkerCount.locationId)) {
									count += interestedWorkerCount.count;
								}
							} else {
								count += interestedWorkerCount.count;
							}
						}
					);
					return count;
				} catch (e) {
					console.error("Get interested worker error", e);
					return 0;
				}
			};

			return {
				SkillName: item.skillName,
				...skillPriority,
				...workersAtEachLevel,
				InterestedWorkers: getInterestedWorkersCount(item),
				WorkersWithSkill: workerCoverageCount,
			};
		});
		return template;
	};

	const handleSelectColumns = (checked: boolean, column: string) => {
		setOverAllColumns(
			overAllColumns.map((item: columnType) => {
				if (item.field === column) {
					return {
						...item,
						checked: !item.checked,
					};
				} else {
					return item;
				}
			})
		);
	};

	return (
		<Box mb={1}>
			<Box
				display='flex'
				justifyContent='space-between'
				flexDirection={width > 719 ? "row" : "column"}
				sx={{ flex: 1 }}
				alignItems={width > 719 ? "center" : "flex-start"}
				mb={1}
			>
				<Box ml={-1}>
					<ColumnSelector
						disabledColumns={["workerName"]}
						columns={overAllColumns}
						handleSelectColumns={handleSelectColumns}
					/>
				</Box>
				<Box display='flex' mx={0.5} alignItems='center'>
					<InsightReportLocationFilter
						allLocations={allLocations}
						setSelectedLocations={setSelectedLocations}
						selectedLocations={selectedLocations}
					/>
				</Box>
				<Box my={-1} width={"100%"}>
					<Searchbar
						placeholder={ ""
							// selectedKPI !== "skillsWithLessThenXWorkersKPI" &&
							// selectedKPI !== "interestedWorkersKPI"
							// 	? "Worker Name" +
							// 	  (islocationColumnEnabled ? ", Worker Location" : "") +
							// 	  (isworkcenterColumnEnabled ? ", Workcenter" : "") +
							// 	  (isprimaryJobColumnEnabled ? ", Primary Job" : "")
							// 	: "Skill Name"
						}
						setSearch={(value: any) =>
							setSearchText(value.replace(/[^a-zA-Z0-9 ]/g, ""))
						}
						value={searchText}
						debounce
					/>
				</Box>
				<Box sx={{ marginLeft: width > 719 ? 3 : 0 }}>
					<ExportButton data={exportFilteredData()} />
				</Box>
			</Box>

			<CrossSkillingDashboardSummaries
				fetchWorkerInsightReportStatus={fetchWorkerInsightReportStatus}
				fetchSkillInsightReportStatus={fetchSkillInsightReportStatus}
				skillsWithLessThanXWorkers={skillsWithLessThanXWorkers}
				workersWithLessThenXSkills={workersWithLessThenXSkills}
				noOfWorkers={noOfWorkers}
				noOfSkills={noOfSkills}
				selectedKPI={selectedKPI}
				setKpi={setKpi}
				kpiCount={kpiCount}
			/>
		</Box>
	);
};

export default CrossSkillingDashboardDataHeader;
