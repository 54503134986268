import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";

type ReviewCheckboxProps = {
	amISure?: boolean;
	setAmISure?: (value: boolean) => void;
};

const ReviewCheckbox = ({ amISure, setAmISure }: ReviewCheckboxProps) => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={amISure}
					onChange={() => setAmISure && setAmISure(!amISure)}
					name='amISureCheckbox'
					id='amISureCheckbox'
					color='primary'
				/>
			}
			label='I agree to publish the selected Shifts'
			style={{ marginRight: "auto", marginLeft: "9px" }}
		/>
	);
};

export default ReviewCheckbox;
