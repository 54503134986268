import { Work } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import React from "react";

import { formatDate } from "../../../../../../utils/helpers";
import JobFilters from "../filters/job-filters";
import LocationFilters from "../filters/location-filters";
import WorkcenterFilters from "../filters/workcenter-filters";
const useBmessageColumns = () => {
	const columns: GridColDef[] = [
		// {
		// 	field: "id",
		// 	headerName: "id",
		// 	width: 260,
		// 	hide: "true",
		// },
		{
			field: "sender",
			headerName: "Sent By",
			width: 140,
			valueGetter: (params) => {
				const senderName = `${params?.row?.createdBy.firstName} ${params?.row?.createdBy.lastName}`;
				return senderName;
			},
			renderCell: (params) => {
				const senderName = `${params?.row?.createdBy.firstName} ${params?.row?.createdBy.lastName}`;
				return (
					<Typography
						sx={{
							fontWeight: 400,
							fontSize: 14,
							fontFamily: "Roboto",
							color: "#000000",
						}}
					>
						{senderName}
					</Typography>
				);
			},
		},
		{
			field: "broadcastDate",
			headerName: "Sent Date",
			width: 170,
			renderCell: (params: any) => {
				const startDate = formatDate({
					dateStr: params.value,
					formatType: "SHORT_DATE",
				})
				const time = formatDate({
					dateStr: params.value,
					formatType: "TIME",
				})
				return (
					<Box>
						<Typography
							sx={{
								fontWeight: 400,
								fontSize: 14,
								fontFamily: "Roboto",
								color: "#000000",
							}}
						>
							{startDate} {time}
						</Typography>
						{/* <Typography
							sx={{
								fontWeight: 400,
								fontSize: 14,
								fontFamily: "Roboto",
								color: "#000000",
							}}
						>
							{time}
						</Typography> */}
					</Box>
				);
			},
		},
		{
			field: "Filters",
			headerName: "Worker Groups",
			flex: 1,
			width: 330,
			sortable: false,
			renderCell: (params) => {
				const roles = params?.row?.jobs;
				const locations = params?.row?.locations;
				const workcenters = params?.row?.workCenters ?? [];
				return (
					<Box width={330}>
						<LocationFilters locations={locations} />
						<WorkcenterFilters roles={workcenters} />
						<JobFilters roles={roles} />
					</Box>
				);
			},
		},
		{
			field: "workerCount",
			headerName: "Workers",
			width: 140,
			renderCell: (params) => {
				return (
					<Typography
						sx={{
							fontWeight: 400,
							fontSize: 14,
							fontFamily: "Roboto",
							color: "#000000",
						}}
					>
						{params.value}
					</Typography>
				);
			},
		},
		{
			field: "message",
			headerName: "Message",
			width: 240,
			renderCell: (params) => {
				return (
					<Typography
						sx={{
							fontWeight: 400,
							fontSize: 14,
							fontFamily: "Roboto",
							color: "#000000",
						}}
					>
						{params.value}
					</Typography>
				);
			},
		},
	];
	return { columns };
};

export default useBmessageColumns;
