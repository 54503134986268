/* eslint-disable simple-import-sort/imports */
import SortedDataGridPro6 from "../../../../design/sorted-datagrid-pro-6";
import { Box, Tooltip } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import CrossSkillLegends from "../../../cross-skilling/ui/cross-skill-header/ui/cross-skill-ledgends";
import SkeletonLoadingOverlay from "../../../../utils/use-skeleton-loading-overlay";
import useTableHeight from "../../../../utils/use-table-height";
import Highlight from "../../../Highlight";
import { openWorkerInsightDetails } from "../../slices/worker-insight-report-slice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {formatSkillScore} from "../../../../utils/helpers";
import JobColor from "../../../../design/ui/job-color";

const WorkerInsightReportTable = ({
	workersReportdata,
	searchText,
	fetchWorkerInsightReportStatus,
	allSkillLevels,
	overAllColumns,
	dispatch,
	allLocations,
	allPriorities,
	isSkillPriorityEnabled,
	jobSkillLevelMapping,
}: any) => {
	const { height, boxRef } = useTableHeight();

	let rows: any = [];
	const col: any = [];

	//Used in Column selector
	const location: boolean = overAllColumns.find(
		(item: any) => item.field === "workerLocation" && item.checked === true
	);
	const workCenter: boolean = overAllColumns.find(
		(item: any) => item.field === "workcenterName" && item.checked === true
	);
	const workerName: boolean = overAllColumns.find(
		(item: any) => item.field === "workerName" && item.checked === true
	);
	const joiningDate: boolean = overAllColumns.find(
		(item: any) => item.field === "joiningDate" && item.checked === true
	);
	const interestedSkills: boolean = overAllColumns.find(
		(item: any) => item.field === "interestedSkillsCount" && item.checked === true
	);
	const totalSkills: boolean = overAllColumns.find(
		(item: any) => item.field === "totalSkills" && item.checked === true
	);
	const workerBackup: boolean = overAllColumns.find(
		(item: any) => item.field === "workerBackupCount" && item.checked === true
	);

	const skillScore: boolean = overAllColumns.find(
		(item: any) => item.field === "skillScore" && item.checked === true
	);
	const primaryJob: boolean = overAllColumns.find(
		(item: any) => item.field === "primaryJobName" && item.checked === true
	);

	const skillsDemonstrated: boolean = overAllColumns.find(
		(item: any) => item.field === "workerSkillsDemostratedCount" && item.checked === true
	);

	const skillsGap: boolean = overAllColumns.find(
		(item: any) => item.field === "workerSkillsGapCount" && item.checked === true
	);

	const otherSkills: boolean = overAllColumns.find(
		(item: any) => item.field === "workerOtherSkillsCount" && item.checked === true
	);

	const requiredSkills: boolean = overAllColumns.find(
		(item: any) => item.field === "requiredSkillsCount" && item.checked === true
	);

	//Worker Name Column
	workerName &&
		col.push({
			field: "workerName",
			headerName: "Worker Name",
			minWidth: 130,
			flex: 1,
			renderHeader: () => (
				<Tooltip title='Worker Name'>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Worker Name"}
					</span>
				</Tooltip>
			),
			renderCell: (params: any) => {
				const fullName = `${params?.row?.workerName}`;
				return (
					<Tooltip title={fullName}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								marginLeft: "12px",
							}}
						>
							<Highlight searchText={searchText} value={fullName} />
						</span>
					</Tooltip>
				);
			},
		});

	//Location Name Column
	location &&
		col.push({
			field: "workerLocation",
			headerName: "Worker Location",
			minWidth: 138,
			flex: 1,
			renderHeader: () => (
				<Tooltip title='Worker Location'>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Worker Location"}
					</span>
				</Tooltip>
			),
			renderCell: (params: any) => (
				<Tooltip title={params.value}>
					<span
						style={{
							marginLeft: "12px",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
					>
						<Highlight
							searchText={searchText}
							value={params?.row?.workerLocation}
						/>
					</span>
				</Tooltip>
			),
		});

	//WorkCenter Name Column
	workCenter &&
		col.push({
			field: "workcenterName",
			headerName: "Workcenter",
			minWidth: 130,
			flex: 1,
			renderHeader: () => (
				<Tooltip title='Workcenter'>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Work Center"}
					</span>
				</Tooltip>
			),
			renderCell: (params: any) => (
				<Tooltip title={params.value}>
					<span
						style={{
							marginLeft: "12px",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
					>
						<Highlight
							searchText={searchText}
							value={params?.row?.workcenterName ?? "-"}
						/>
					</span>
				</Tooltip>
			),
		});

	primaryJob &&
		col.push({
			field: "primaryJobName",
			headerName: "Primary Job",
			minWidth: 180,
			flex: 1,
			renderHeader: () => (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Primary Job"}
					</span>
				</Box>
			),
			renderCell: (params: any) => (
				<Tooltip title={params.value}>
					<Box sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "flex-start",
						width: "100%",
					}}>
						{params?.row?.primaryJobName && <JobColor color={`${params?.row?.primaryJobHexColor}`} />}
						<span
							style={{
								marginLeft: "8px",
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
						<Highlight
							searchText={searchText}
							value={params?.row?.primaryJobName || "-"}
						/>
					</span>
					</Box>
				</Tooltip>
			),
			valueGetter: (params: any) => {
				return params.row.primaryJobName;
			},
		});

	allPriorities &&
		isSkillPriorityEnabled &&
		allPriorities.length > 0 &&
		allPriorities.forEach((x: any) => {
			if (col.find((item: any) => item.field === `priority${x.id}`)) return;
			else
				col.push({
					field: `priority${x.id}`,
					align: "center",
					renderHeader: () => (
						<Tooltip title={`${x.code} (${x.name})`}>
							<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
								{`${x.code} (${x.name})`}
							</span>
						</Tooltip>
					),
					headerName: x.name,
					minWidth: 120,
					flex: 1,
					headerAlign: "center",
					renderCell: (params: any) => {
						return params.row[`priority${x.id}`]
							? params.row[`priority${x.id}`]
							: "-";
					},
				});
		});

	//Joining Date column
	joiningDate &&
		col.push({
			field: "joiningDate",
			headerName: "Joining Date",
			minWidth: 115,
			flex: 1,
			renderHeader: () => (
				<Tooltip title='Joining Date'>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Joining Date"}
					</span>
				</Tooltip>
			),
			renderCell: (params: any) => {
				const joiningDate = params?.row.joiningDate.replace(/-/g, "/");
				return (
					<Tooltip title={joiningDate}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								marginLeft: "12px",
							}}
						>
							{joiningDate}
						</span>
					</Tooltip>
				);
			},
		});

	//Required skills column
	jobSkillLevelMapping &&
		requiredSkills &&
		col.push({
			field: "requiredSkillsCount",
			headerName: "Required Skills",
			minWidth: 180,
			flex: 1,
			align: "center",
			headerAlign: "center",
			valueGetter: (params: any) => {
				if (params.row?.requiredSkillsCount === null ||
					params.row?.requiredSkillsCount === undefined
				) return "-";
				return params.row?.requiredSkillsCount;
			},
			renderHeader: () => (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
						{"Required Skills"}
					</span>
				</Box>
			),
		});

	//Skill coverage column
	!jobSkillLevelMapping &&
	totalSkills &&
		col.push({
			field: "totalSkills",
			headerName: "Total Skills",
			minWidth: 148,
			flex: 1,
			align: "center",
			headerAlign: "center",
			renderHeader: () => {
				const text =
					allSkillLevels.length > 0
						? allSkillLevels
								.filter(
									(curr: { isQualifiedAsSkilled: boolean }) =>
										curr.isQualifiedAsSkilled
								)
								.map((curr: { name: string }) => curr.name)
								.join(", ")
						: "";

				const tooltip =
					allSkillLevels.length > 0
						? `Worker's Skills with qualified competency levels - ${text}`
						: "Total number of skills the worker has qualified as skilled";

				return (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
							Total Skills
						</span>
						<Tooltip title={tooltip}>
							<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />
						</Tooltip>
					</Box>
				);
			},
		});

	jobSkillLevelMapping &&
	skillsDemonstrated &&
	col.push({
		field: "workerSkillsDemostratedCount",
		headerName: "Skills Demonstrated",
		minWidth: 180,
		flex: 1,
		align: "center",
		headerAlign: "center",
		valueGetter: (params: any) => {
			if (params.row?.workerSkillsDemostratedCount === null ||
				params.row?.workerSkillsDemostratedCount === undefined
			) return "-";
			return params.row?.workerSkillsDemostratedCount;
		},
		renderHeader: () => (
			<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
						{"Skills Demonstrated"}
					</span>
				{/*<Tooltip title='Difference between required and total skills'>*/}
				{/*	<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />*/}
				{/*</Tooltip>*/}
			</Box>
		),
	});

	jobSkillLevelMapping &&
		skillsGap &&
		col.push({
			field: "workerSkillsGapCount",
			headerName: "Skills Gap",
			minWidth: 180,
			flex: 1,
			align: "center",
			headerAlign: "center",
			valueGetter: (params: any) => {
				if (params.row?.workerSkillsGapCount === null
					|| params.row?.workerSkillsGapCount === undefined
				) return "-";
				return params.row?.workerSkillsGapCount;
			},
			renderHeader: () => (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
						{"Skills Gap"}
					</span>
					{/*<Tooltip title='Difference between required and total skills'>*/}
					{/*	<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />*/}
					{/*</Tooltip>*/}
				</Box>
			),
		});

	jobSkillLevelMapping &&
	otherSkills &&
	col.push({
		field: "workerOtherSkillsCount",
		headerName: "Other Skills",
		minWidth: 180,
		flex: 1,
		align: "center",
		headerAlign: "center",
		valueGetter: (params: any) => {
			if (params.row?.workerOtherSkillsCount === null ||
				params.row?.workerOtherSkillsCount === undefined
			) return "-";
			return params.row?.workerOtherSkillsCount;
		},
		renderHeader: () => (
			<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
						{"Other Skills"}
					</span>
				{/*<Tooltip title='Difference between required and total skills'>*/}
				{/*	<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />*/}
				{/*</Tooltip>*/}
			</Box>
		),
	});

	isSkillPriorityEnabled &&
    skillScore &&
    col.push({
      field: "skillScore",
      headerName: "Score",
      minWidth: 180,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span style={{ padding: "0px 6px", fontWeight: "bold" }}>
            {"Total Skill Score"}
          </span>
        </Box>
      ),
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <span
            style={{
              marginLeft: "12px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <Highlight
              searchText={searchText}
              value={formatSkillScore(params?.row?.skillScore)}
            />
          </span>
        </Tooltip>
      ),
    });

	//Interested skills column
	interestedSkills &&
		col.push({
			field: "interestedSkillsCount",
			headerName: "Interested Skills",
			minWidth: 185,
			flex: 1,
			align: "center",
			headerAlign: "center",
			renderHeader: () => (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
						{"Interested Skills"}
					</span>
					<Tooltip title='Skills that the Worker has voluntarily showed interest in learning'>
						<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />
					</Tooltip>
				</Box>
			),
		});

	//Worker backup column
	workerBackup &&
		col.push({
			field: "workerBackupCount",
			headerName: "Worker Backup",
			minWidth: 180,
			flex: 1,
			align: "center",
			headerAlign: "center",
			renderHeader: () => (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
						{"Worker Backup"}
					</span>
					<Tooltip title="Coworkers with the Worker's Skills and the Worker's competency levels">
						<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />
					</Tooltip>
				</Box>
			),
		});

	//Dynamic skill levels columns
	!isSkillPriorityEnabled &&
		allSkillLevels !== undefined &&
		allSkillLevels.length > 0 &&
		allSkillLevels.map((x: any) => {
			col.push({
				field: `level${x.level}`,
				align: "center",
				renderHeader: () => (
					<Box>
						<CrossSkillLegends
							isSkillLevelShownInTableHeader={true}
							allSkillLevels={[allSkillLevels[x.level]]}
						/>
					</Box>
				),
				headerName: x.name,
				minWidth: 110,
				flex: 1,
				headerAlign: "center",
				renderCell: (params: any) => {
					return params.row[`level${x.level}`]
						? params.row[`level${x.level}`]
						: "-";
				},
			});
		});

	rows = workersReportdata
		.map((item: any) => {
			const skills: any = {};
			let skillsCoverageTotal = 0;

			item.workerSkillCountByLevel.map((skill: any) => {
				skills[`level${skill.level}`] = skill.count;
				const level = allSkillLevels.find(
					(x: { level: number }) => x.level === skill.level
				);
				if (level && level.isQualifiedAsSkilled) {
					skillsCoverageTotal += skill.count;
				}
			});

			const priority: any = {};
			item.workerSkillCountByPriority.map((w: any) => {
				priority[`priority${w.priorityId}`] = w.count;
			});

			return {
        ...item,
        id: item.workerId,
        workerLocation: item.locationName,
        workCenter: !item.workcenterName ? "-" : item.workcenterName,
        workerName: (item.firstName + " " + item.lastName).trim(),
        joiningDate: format(new Date(item.joiningDate), "MM-dd-yyyy"),
        interestedSkills: item.interestedSkillsCount,
		requiredSkillsCount: item.requiredSkillsCount,
		totalSkills:  skillsCoverageTotal,
		workerSkillsDemostratedCount: item.workerSkillsDemostratedCount,
		workerSkillsGapCount: item.workerSkillsGapCount,
		workerOtherSkillsCount: item.workerOtherSkillsCount,
        workerBackup: item.workerBackupCount,
        skillScore: item.skillScore,
        primaryJobName: item.primaryJobName,
        primaryJobHexColor: `#${item.primaryJobColor}`,
        ...priority,
        ...skills,
      };
		})
		.sort((a: any, b: any) => a.workerName.localeCompare(b.workerName));

	const handleRowClick = (params: any) => {
		const workerId = params.row.id;
		dispatch(
			openWorkerInsightDetails({ workerId: workerId, locations: allLocations })
		);
	};

	return (
		<Box
			ref={boxRef}
			style={{
				overflow: "hidden",
				width: "100%",
				height: "calc(100vh - 320px)",
				minHeight: "200px",
			}}
		>
			<SortedDataGridPro6
				rows={rows}
				columns={col}
				onRowClick={handleRowClick}
				experimentalFeatures={{ columnGrouping: true }}
				loading={fetchWorkerInsightReportStatus === "pending"}
				defaultSortField='workerName'
				slots={{
					loadingOverlay: () => (
						<SkeletonLoadingOverlay
							columnData={{
								columnName: "workerName",
								columnStyle: { marginLeft: "12px" },
							}}
						/>
					),
				}}
				disableColumnFilter
				disableColumnMenu
				pagination
				disableColumnSelector
				disableDensitySelector
				disableColumnReorder
				sx={{
					overflow: "hidden",
					border: "none !important",
					"& .MuiDataGrid-row": {
						cursor: "pointer",
					},
					"& .MuiDataGrid-cell:focus": {
						outline: "none",
					},
				}}
			/>
		</Box>
	);
};
export default WorkerInsightReportTable;
