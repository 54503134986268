import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Switch,
	Typography,
} from "@mui/material";
import React, { useMemo } from "react";

import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import AllowedWorkcenters from "./allowed-workcenters";

interface WorkCenter {
	id: string | number;
	name: string;
	isOverlapSignupEnabled: boolean;
}

interface OverlapShiftsProps {
	newShiftSettings: {
		partialShiftAllowed: boolean;
		minPartialShiftHours: number;
	};
	setNewShiftSettings: React.Dispatch<React.SetStateAction<any>>;
	loading: boolean;
	partialShiftWorkcenters: any;
	newPartialShiftWorkcenters: any;
	setNewPartialShiftWorkcenters: React.Dispatch<React.SetStateAction<any>>;
}

const FlexSignup: React.FC<OverlapShiftsProps> = ({
	newShiftSettings,
	setNewShiftSettings,
	loading,
	partialShiftWorkcenters,
	newPartialShiftWorkcenters,
	setNewPartialShiftWorkcenters,
}) => {
	const {
		getLocationSettingsStatus,
		currentLocationData,
		locationSettingsObj,
		updateAllowedWorkCentersForShiftsStatus,
		minShiftDurationHours,
		maxShiftDurationHours,
	} = useGatAdminConfigData();

	const { wc, locationWorkcenters } = useMemo(() => {
		if (!currentLocationData?.workCenters) {
			return { wc: {}, locationWorkcenters: [] };
		}

		const wc = Object.fromEntries(
			currentLocationData.workCenters.map((wc: WorkCenter) => [wc.id, wc.name])
		);

		return {
			wc,
			locationWorkcenters: currentLocationData.workCenters,
		};
	}, [currentLocationData?.workCenters]);

	const handleWorkcenterChange = (
		event: SelectChangeEvent<typeof partialShiftWorkcenters>
	) => {
		const value = event.target.value;
		if (value.includes("all")) {
			if (newPartialShiftWorkcenters.length === locationWorkcenters.length) {
				setNewPartialShiftWorkcenters([]);
			} else {
				setNewPartialShiftWorkcenters(
					locationWorkcenters?.map((wc: { id: number }) => wc.id) || []
				);
			}
		} else {
			setNewPartialShiftWorkcenters(value as string[]);
		}
	};

	const handleDelete = (
		event: React.MouseEvent,
		workcenterToDelete: string
	) => {
		event.stopPropagation();
		if (!newShiftSettings.partialShiftAllowed) {
			return;
		}
		setNewPartialShiftWorkcenters(
			newPartialShiftWorkcenters.filter(
				(wc: string) => wc !== workcenterToDelete
			)
		);
	};
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Allow Workers to sign up for partial Shifts (either half of the
						Shift)
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Enable Workers to pick up one half of the Shift if they cannot make
						it for the full Shift
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{loading || getLocationSettingsStatus === "pending" ? (
						<Skeleton width={50} height={40} />
					) : (
						<Switch
							checked={newShiftSettings.partialShiftAllowed ?? false}
							onChange={(e) => {
								setNewShiftSettings((prevSettings: any) => ({
									...prevSettings,
									partialShiftAllowed: e.target.checked,
									minPartialShiftHours: e.target.checked
										? minShiftDurationHours
										: prevSettings.minPartialShiftHours,
								}));
							}}
						/>
					)}
				</Box>
			</Box>

			{newShiftSettings.partialShiftAllowed ? (
				<Box
					sx={{
						marginTop: "32px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<AllowedWorkcenters
							loading={
								loading ||
								updateAllowedWorkCentersForShiftsStatus === "pending" ||
								getLocationSettingsStatus === "pending"
							}
							selectedWorkcenters={newPartialShiftWorkcenters}
							handleWorkcenterChange={handleWorkcenterChange}
							handleDelete={handleDelete}
							locationWorkcenters={locationWorkcenters}
							wc={wc}
							updateStatus={updateAllowedWorkCentersForShiftsStatus}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "16px",
									color: "rgba(0, 0, 0, 0.87)",
									marginLeft: "8px",
								}}
							>
								Minimum Shift duration that allows partial signups
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginLeft: "8px",
							}}
						>
							{getLocationSettingsStatus === "pending" ? (
								<Skeleton width={60} height={50} />
							) : (
								<Select
									id='days-to-accept-shift'
									variant='filled'
									sx={{
										"& .MuiInputBase-input": {
											background: "#FFF",
											padding: "8px",
										},
										width:
											newShiftSettings.minPartialShiftHours > 90
												? "70px"
												: "60px",
									}}
									onChange={(e) => {
										setNewShiftSettings((prevSettings: any) => ({
											...prevSettings,
											minPartialShiftHours: Number(e.target.value),
										}));
									}}
									value={
										newShiftSettings.minPartialShiftHours ??
										minShiftDurationHours
									}
									// scroll on menu options
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 120,
												overflow: "auto",
											},
										},
									}}
								>
									{/* TODO: Add options from minShiftDurationHours to maxShiftDurationHours */}
									{Array.from(
										{
											length: maxShiftDurationHours - minShiftDurationHours + 1,
										},
										(_, i) => (
											<MenuItem key={i} value={minShiftDurationHours + i}>
												{minShiftDurationHours + i}
											</MenuItem>
										)
									)}
								</Select>
							)}
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "16px",
									color: "rgba(0, 0, 0, 0.87)",
									marginLeft: "8px",
								}}
							>
								hours
							</Typography>
						</Box>
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default FlexSignup;
