import { DataGridPro, GridColumns, GridRowsProp, GridSortModel } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
interface DataGridProps {
	rows: GridRowsProp;
	columns: GridColumns;
	[key: string]: any; // Additional props
}

const DataGrid: React.FC<DataGridProps> = ({ rows, columns, ...rest }) => {
	const [sortModel, setSortModel] = useState<GridSortModel>([
		{ field: columns[0]?.field, sort: "asc" } ]);
	return (
		<DataGridPro rows={rows} 
		columns={columns}
		sortModel={sortModel}
		onSortModelChange={(newModel : any ) => {
			console.log("Sorting model changed", newModel);
			setSortModel(newModel)}}
		disableColumnMenu {...rest} />
	);
};

export default DataGrid;
