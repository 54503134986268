import { Button } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ShiftDetailsDialog } from "../../../../export/scheduler";
import { resetRenotifyWorkers } from "../../../../export/scheduler";
import { NewShiftDialog } from "../../../../export/shift-scheduler";
import useColumnSelector from "../../../design/column-selector/controllers/use-column-selector";
import useWorkerRequestsData from "../controllers/use-worker-requests-data";
import { resetShiftActions } from "../store/worker-requests-slice";
import useColumnDivider from "./controllers/use-column-divider";
import useFullTimeRequestsColumns from "./controllers/use-full-time-request-columns";
import useFullTimeRequestController from "./controllers/use-full-time-request-controller";
import FullTimeRequestsTable from "./table/full-time-requests-table";
import WorkerRequestDetail from "./ui/worker-request-detail";
import WorkerRequestHeader from "./ui/worker-request-header";

interface FullTimeShiftsSwapProps {
	setFullTimeRequestsLength: (length: number) => void;
}

const FullTimeShiftsSwap: React.FC<FullTimeShiftsSwapProps> = ({
	setFullTimeRequestsLength,
}) => {
	const {
		requestActionStatus,
		requestActionErrorMessage,
		renotifyWorkersStatus,
	} = useWorkerRequestsData();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleClose = () => {
		dispatch(resetShiftActions());
		if (requestActionStatus !== "error") {
			navigate("/login");
		}
	};

	const [
		{
			isCreateNewShiftDialogOpen,
			isShiftDialogOpen,
			draftShiftDate,
			searchQuery,
			isShiftCoverage,
			rows,
			requestStatus,
			timeFilterOption,
			shiftDetailsId,
		},
		{
			openCreateNewShiftDialog,
			setIsShiftDialogOpen,
			closeCreateNewShiftDialog,
			setSearchQuery,
			handleShiftCoverage,
			setRequestStatus,
			setTimeFilterOption,
			openShifDetailsDialog,
			closeShiftDetailsDialog,
		},
	] = useFullTimeRequestController();
	const columns = useFullTimeRequestsColumns(searchQuery ?? "");

	const fixedColumns = ["workerName", "actions"];
	const showByDefault = [
		"requestType",
		"shiftDetails",
		"timeOffDuration",
		"comment",
		"status",
		"leaveType",
	];
	const { defaultColumns, restColumns } = useColumnDivider(
		columns,
		fixedColumns,
		showByDefault
	);
	//	console.log("defaultColumns", defaultColumns, "restColumns", restColumns);
	const [{ selectedColumns, pickerColumns }, { handleSelectColumns }] =
		useColumnSelector(defaultColumns, restColumns);

	useEffect(() => {
		if (rows) setFullTimeRequestsLength(rows.length);
	}, [rows, setFullTimeRequestsLength]);

	return (
		<Box>
			<WorkerRequestHeader
				search={setSearchQuery}
				value={searchQuery ?? ""}
				columns={pickerColumns}
				handleSelectColumns={handleSelectColumns}
				statusOptions={requestStatus}
				setStatusOptions={setRequestStatus}
				timeFilterOption={timeFilterOption}
				setTimeFilterOption={setTimeFilterOption}
			/>
			<FullTimeRequestsTable
				openCreateNewShiftDialog={openCreateNewShiftDialog}
				openShiftDetailsDialog={openShifDetailsDialog}
				columns={columns.filter((column: any) =>
					selectedColumns?.includes(column.field)
				)}
				rows={rows}
			/>
			{isCreateNewShiftDialogOpen ? (
				<NewShiftDialog
					open={isCreateNewShiftDialogOpen}
					handleClose={closeCreateNewShiftDialog}
					draftShiftDate={
						draftShiftDate ? new Date(draftShiftDate) : new Date()
					}
					isShiftSwap={true}
					requestComponent={
						<WorkerRequestDetail
							handleShiftCoverage={handleShiftCoverage}
							isShiftCoverage={isShiftCoverage}
						/>
					}
					isShiftCoverage={isShiftCoverage}
				/>
			) : null}
			{isShiftDialogOpen ? (
				<ShiftDetailsDialog
					open={isShiftDialogOpen}
					handleClose={closeShiftDetailsDialog}
					shiftId={shiftDetailsId}
					isWorkerRequest={true}
				/>
			) : null}

			{requestActionStatus === "fulfilled" ? (
				<Snackbar
					open={requestActionStatus === "fulfilled"}
					autoHideDuration={3000}
					onClose={() => dispatch(resetShiftActions())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Shif Status Updated Successfully
					</MuiAlert>
				</Snackbar>
			) : null}

			{requestActionStatus === "error" ? (
				<Snackbar
					open={requestActionStatus === "error"}
					autoHideDuration={5000}
					onClose={handleClose}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert
						elevation={6}
						variant='filled'
						severity='error'
						onClose={handleClose}
					>
						<Box display='flex' flexDirection='column' alignItems='center'>
							<Box mb={1}>
								{(requestActionErrorMessage as string) ?? "Error"}
							</Box>
							{/*<Button*/}
							{/*	variant="contained"*/}
							{/*	color="primary"*/}
							{/*	onClick={() => history.push("/login")}*/}
							{/*>*/}
							{/*	Try Login Again*/}
							{/*</Button>*/}
						</Box>
					</MuiAlert>
				</Snackbar>
			) : null}
			{renotifyWorkersStatus === "fulfilled" ? (
				<Snackbar
					open={renotifyWorkersStatus === "fulfilled"}
					autoHideDuration={3000}
					onClose={() => dispatch(resetRenotifyWorkers(undefined))}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Workers Notified Successfully.
					</MuiAlert>
				</Snackbar>
			) : null}
			{renotifyWorkersStatus === "error" ? (
				<Snackbar
					open={renotifyWorkersStatus === "error"}
					autoHideDuration={5000}
					onClose={() => dispatch(resetRenotifyWorkers(undefined))}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert
						elevation={6}
						variant='filled'
						severity='error'
						onClose={handleClose}
					>
						<Box display='flex' flexDirection='column' alignItems='center'>
							<Box mb={1}>Error while notifying workers. Please try again.</Box>
						</Box>
					</MuiAlert>
				</Snackbar>
			) : null}
		</Box>
	);
};

export default FullTimeShiftsSwap;
